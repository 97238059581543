import * as React from "react";
import { styled } from "@mui/material/styles";

import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { CheckCircle} from "@mui/icons-material";

import Modal from '@mui/material/Modal';
import ProfileClasses from "./Profile.module.css";

export default function LoginDetail() {
  
  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    borderRadius: "14px",
    boxShadow: "none"
  }));

 
  /*------------------ Modal JS -------------------*/
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  

  const [openAddEmail, emailMod] = React.useState(false);
  const addEmail = () => emailMod(true);
  const closeEmail = () => emailMod(false);
  

  /*-----------------------------------------------*/
  
  return (
    <>

      <Item sx={{marginBottom: ['20px','30px','40px']}}>
        <Box className={ProfileClasses.profileDetails}>
          <Typography variant="h3">Login Detail</Typography>

          <List className={ProfileClasses.profileDataList}>
            <ListItem>
              <ListItemText
                primary="Mobile Number"
                secondary="+91 9876543210"
              />
              <small className={ProfileClasses.verified}><CheckCircle /> Verified</small>
              
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Email Address"
                secondary="david@teckborn.com"
              />
              <small className={ProfileClasses.nonVerified}><CheckCircle /> Not Verified</small>
              <Button onClick={addEmail}>
                <img
                  src="/assets/images/profilepage/pencil.svg"
                  alt="Menu"
                  className={ProfileClasses.profileEdit}
                />
              </Button>
            </ListItem>
          </List>
        </Box>
      </Item>

      <Modal
            open={openAddEmail}
            onClose={closeEmail}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
          <Box sx={style} className={ProfileClasses.modal}>
            <Button onClick={closeEmail} className={ProfileClasses.closeBtn}>
              <img
                src="/assets/images/profilepage/close.svg"
                alt="Menu"
                className={ProfileClasses.profileEdit}
              />
            </Button>
            <Typography id="modal-modal-title" variant="h5" component="h2" sx={{textAlign:"center"}}>
              Add Your Email
            </Typography>

            <Typography variant="h6" sx={{fontSize:"14px", textAlign:"center", margin:"20px auto 0", width:"50%"}} className={ProfileClasses.modalSubtext}>
              <span>For a faster booking experience, exclusive offers and rewards</span>
            </Typography>
            <Box className={ProfileClasses.modalForm}>
              <Grid columns={{ xs: 12, md: 12 }} spacing={{ xs: 2, sm:2, md: 2 }} container>
                  <Grid item md={12} sm={12} xs={12} container>
                      <TextField
                        label="Email*"
                        placeholder="Enter Your Email"
                        color="primary"
                        type="text"
                        fullWidth
                      />
                  </Grid>

                  <Grid item md={12} sm={12} xs={12} container>
                      <Button variant="contained" fullWidth className={ProfileClasses.updateBtn}>
                        Add Email
                      </Button>
                  </Grid>

              </Grid>
            </Box>
          </Box>
        </Modal>

    </>
  );
}
