import React from "react";
import { Box, Container,  Grid } from "@mui/material";
import NodueClasses from "./NoDue.module.css";
const NoDue = () => {
  return (
    <>
      <Box
	  className={NodueClasses.outerBox}
        sx={{
          width: "100%",
          height: "auto",          
          paddingTop: "1rem",
          paddingBottom: "1rem",
		  background:"#F5F6FF",
		  marginTop:"2rem"
        }}
        display={{ xs: "block", md: "block" }}
      >
        <Container maxWidth="xl" >
              <Grid
            display={{ xs: "flex", md: "flex" }}
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item alignSelf="center" xs={4} md={2}>
             <div  className={NodueClasses.innerBox}>
			 
			 <img src="/assets/images/NoDue/vi.svg" alt="vi" />
			  <p>   Mobile Number</p>
			  </div>
			  <span>7012345678</span>
            </Grid>
			
			  <Grid item alignSelf="center" xs={4} md={2}>
               <div  className={NodueClasses.innerBox}>
			    <img src="/assets/images/NoDue/calendar.svg" alt="vi" /> 
			  <p> Bill Date</p>
			  	  </div>
			  <span>01-01-2022</span>
            </Grid>
			
			  <Grid item alignSelf="center" xs={4} md={2}>
               <div  className={NodueClasses.innerBox}>
			    <img src="/assets/images/NoDue/calendar1.svg" alt="vi" />
			  <p>  Due Date</p>
			  	  </div>
			  <span>01-03-2022 </span>
            </Grid>
			
			  <Grid item alignSelf="center" xs={4} md={2}>
               <div  className={NodueClasses.innerBox}>
			    <img src="/assets/images/NoDue/rupee.svg" alt="vi" /> 
			  <p>  Amount</p>
			  	  </div>
			  <span>353.8</span>
            </Grid>
			  <Grid item alignSelf="end" md={2}>
             
		<h3>	Fetch updated bill
		</h3>
            </Grid>
			
		
			           
          </Grid>
        </Container>
      </Box>
	  
	  
	       <Box
	  className={NodueClasses.outerBox}
        sx={{
          width: "100%",
          height: "auto",          
          paddingTop: "1rem",
          paddingBottom: "1rem",
		  background:"#F5F6FF",
		  marginTop:"2rem"
        }}
        display={{ xs: "block", md: "block" }}
      >
        <Container maxWidth="xl" >
              <Grid
            display={{ xs: "flex", md: "flex" }}
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item alignSelf="center" xs={8} md={8}>
             <div  className={NodueClasses.innerBox}>
			 
	<h2>No Pending Bills</h2>
			
			  </div>
			  <p>All the bills are cleared for this account/number</p>
            </Grid>
			
			  <Grid item alignSelf="center" xs={4} md={4}>
               <div  className={NodueClasses.innerBox}>
			    <img src="/assets/images/NoDue/no-due-bill.png" alt="vi" /> 
			 
			  	  </div>
			
            </Grid>
				
		
			           
          </Grid>
        </Container>
      </Box>

    </>
  );
};

export default NoDue;
