import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { routes as appRoutes } from "./routes";
import Layout from "./components/Layout/Layout";
import "./App.css"

function App() {
  // define theme

  const theme = createTheme({
    palette: {
      primary: {
        light: "#F4F5F7",
        main: "#0989e3",
        dark: "#2341D5",
        contrastText: "#91959E",
      },
      secondary: {
        main: "#4db6ac",
        light: "#82e9de",
        dark: "#00867d",
        contrastText: "#000",
      },
    },  
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          // root: {
          //     position: 'relative',
          //     '& $notchedOutline': {
          //         borderColor: 'red',
          //     },
          //     '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          //         borderColor: 'green',
          //         '@media (hover: none)': {
          //             borderColor: 'blue',
          //         },
          //     },
          //     '&$focused $notchedOutline': {
          //         borderColor: 'red',
          //         borderWidth: 1,
          //     },
          // },


          root: {
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: "green"
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: "red"
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "purple"
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
              color: "purple"
            },
            "& .MuiInputLabel": {
              color: "green"
            },
            "&:hover .MuiInputLabel": {
              color: "red"
            },
            "& .MuiInputLabel.Mui-focused": {
              color: "purple"
            }
          }
      },
    }
    }
  
    // components: {
    //   MuiTab: {
    //     styleOverrides: {
    //     root: {
    //       '&.Mui-selected': {
    //         boxShadow: '3px 4px 9px 3px #c3c6e94d',
    //       },
    //     },
    //   }
    //   }
    // },		
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Layout>
          <Routes>
            {appRoutes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                element={<route.component />}
              />
            ))}
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
