import React, { ReactElement, FC } from "react";
import DthForm from "../components/Modules/DthForm";
import MobilePlans from "../components/Modules/MobilePlans";

const Prepaid: FC<any> = (): ReactElement => {
  return (
    <>

 <DthForm/>
 <MobilePlans/>
   </>
  );
};

export default Prepaid;