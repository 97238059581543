import TabClasses from "./Tab.module.css";
import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Plans from "./Plans";
import "./style.css";
import NoDue from "../NoDue/NoDue";
import Operators from "../Operators/Operators";
import Faqs from "../Faqs/Faqs";
import Circle from "../Circle/Circle";

const StyledTextField = styled(TextField)({
	 "& .MuiFilledInput-root": {
      backgroundColor: "#fff",
	  border: "1px solid #EAEDFE",
	   borderRadius: "10px"
    },
	"& .MuiFilledInput-root:hover": {
      backgroundColor: "#fff",
      "@media (hover: none)": {
        backgroundColor: "#fff"
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "#fff"
    },
	"& .MuiInputLabel-root": {
     fontSize:"16px",
	 fontWeight: "400",
	 color: "#91959E",

    },
	"& .MuiInputLabel-root.Mui-focused": {
		color: "#91959E",
		transform: "translate(14px, 14px) scale(1)",
		
	},
	"& .MuiInputLabel-root:focus": {
		color: "#91959E"
	},
	"& .MuiInputLabel-root:hover": {
		color: "#91959E"
	}
	,
	"& .MuiInputLabel-filled": {		
		transform: "translate(14px, 30px) scale(1)",
		color: "#91959E",
		
	},
	"& .MuiFormLabel-filled": {
		
		transform: "translate(14px, 14px) scale(1)",
		},
			"& .MuiInputLabel-shrink": {
		
		transform: "translate(14px, 14px) scale(1)",
		}
});

const StyledSelect = styled(Select)(`
&.${outlinedInputClasses} {
	min-height:"0"
},
  &.${outlinedInputClasses.notchedOutline} {
    border-color: #EAEDFE;border-radius: 10px;padding:40px 23px;min-height:auto
  }
  &:hover .${outlinedInputClasses.notchedOutline} {
    border-color: #EAEDFE;border-radius: 10px;padding:40px 23px;
  }
  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border-color: #EAEDFE;background:"#fff"
  },
  &.MuiSelect-select{
	  min-height:"0"
  },
  
   .MuiSelect-icon {
    display:none;
  },
`);

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
	 


  const [operator, setOperator] = React.useState("");
 
  const handleOperatorChange = (event: any) => {
    setOperator(event.target.value);
  };

   const [circle, setCircle] = React.useState("");
    const handleCircleChange = (event: any) => {
    setCircle(event.target.value );
	};


  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%",marginBottom: "1rem" }} display={{ sm: "block", xs: "none" }}>
	
        <div className={TabClasses.moduleTabs}>
          <Box sx={{ borderBottom: 1, borderColor: "#EAEDFE" }}>
            <Tabs
              value={value}
              variant="scrollable"
              onChange={handleChange}
              aria-label="module tabs"
              textColor="inherit"
              className={TabClasses.tabs}
              TabIndicatorProps={{ style: { background: "#FAAF56", height:"3px", bottom:"0px",borderRadius:"5px" } }}
            >
              <Tab
			      
                label={<h2>Electricity Bill</h2>}
                icon={
                  <img
                    src="./assets/images/Icons/electricity.svg"
                    alt="prepaid"
                  />
                }
                iconPosition="start"
                {...a11yProps(0)}
              />
              <Tab
                label={<h2>Water Bill</h2>}
                icon={
                  <img
                    src="./assets/images/Icons/water.svg"
                    alt="postpaid"
                  />
                }
                iconPosition="start"
                {...a11yProps(1)}
              />
              <Tab
                label={<h2>Piped Gas</h2>}
                icon={<img src="./assets/images/Icons/piped-gas.svg" alt="dth" />}
                iconPosition="start"
                {...a11yProps(2)}
              />
			  <Tab
                label={<h2>Landline </h2>}
                icon={<img src="./assets/images/Icons/landline.svg" alt="dth" />}
                iconPosition="start"
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <Grid item xs={12} marginBottom="2rem">
              <Typography variant="h1"> Pay your Electricity Bill</Typography>
              <Typography variant="h4">
                Get exciting cashback and offers
              </Typography>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={3}>
            

                  <Grid item sm={6} md={3}  xs={12}>
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                      sx={{
                        "& > :not(style)": { width: "100%" },
                      }}
                    >
                					  
					  
					   <FormControl fullWidth variant="filled" className={TabClasses.selectControl}>
				
              <InputLabel id="demo-simple-select-label" sx={{fontSize:"16px"}} className="selectLabel">Select Circle</InputLabel>			  
			    <StyledSelect
				 	disableUnderline
				 className={TabClasses.selectbox}
				 SelectDisplayProps={{ style: { paddingTop: 45, paddingBottom: 10, fontWeight:500, fontSize: "18px", borderColor:'#91959E' } }}
				labelId="demo-simple-select-label"
                value={circle}
                label="Select Circle"
				onChange={handleCircleChange}
				inputProps={{
                endAdornment: (
                  <InputAdornment
                    className={TabClasses.adornment}
                    position="start"
                  >
                    Change Circle
                  </InputAdornment>
                )
              }}
			  
			  
				MenuProps={{
				sx: {
              "&& .Mui-selected": {
                backgroundColor: "#2341D5",
				color:"#fff"
              },
			   "&& .Mui-selected:hover": {
                backgroundColor: "#2341D5",
				color:"#fff"
              }
            },
			PaperProps: { sx: { maxHeight: 300,boxShadow: "0px 7px 25px rgba(117, 128, 137, 0.25)", borderRadius:"7px" } },
			
          }}
              >
			  	
                <MenuItem value="Kerala">Kerala</MenuItem>
                <MenuItem value="Karnataka">Karnataka</MenuItem>
				 <MenuItem value="Tamil">Tamil Nadu</MenuItem>
                <MenuItem value="Delhi">Delhi</MenuItem>
				 <MenuItem value="Madhya">Madhya Pradesh</MenuItem>
                <MenuItem value="Maharashtra">Maharashtra</MenuItem>
				 <MenuItem value="Andhra">Andhra Pradesh</MenuItem>
                <MenuItem value="Goa">Goa</MenuItem>
           
        </StyledSelect>
		  <InputAdornment
                    className={TabClasses.adornment}
                    position="end"
                  >
                    Change Circle
                  </InputAdornment>
            </FormControl>

                    </Box>
                  </Grid>

                  <Grid item sm={6} md={3}  xs={12}>
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                      sx={{
                        "& > :not(style)": { width: "100%" },
                      }}
                    >
                       <FormControl fullWidth variant="filled" className={TabClasses.selectControl}>
              <InputLabel id="demo-select-label" sx={{fontSize:"16px"}} className="selectLabel">
                Select Operator
              </InputLabel>
			     <StyledSelect
				 	disableUnderline
					inputProps={{
                endAdornment: (
                  <InputAdornment
                    className={TabClasses.adornment}
                    position="end"
                  >
                    Change Operator
                  </InputAdornment>
                )
              }}
				 className={TabClasses.selectbox}
				 SelectDisplayProps={{ style: { paddingTop: 45, paddingBottom: 10, fontWeight:500, fontSize: "18px", borderColor:'#91959E' } }}
				labelId="demo-select-label"
                id="demo-simple-select"
                value={operator}
				onChange={handleOperatorChange}
               
					MenuProps={{
					
				sx: {
              "&& .Mui-selected": {
                backgroundColor: "#2341D5",
				color:"#fff"
              },
			   "&& .Mui-selected:hover": {
                backgroundColor: "#2341D5",
				color:"#fff"
              }
            },
				
          }}
		  		
              >
                 <MenuItem value="Airtel">Airtel</MenuItem>
                <MenuItem value="Jio">Jio</MenuItem>
                <MenuItem value="Vi">Vi</MenuItem>
                <MenuItem value="Jio">Jio</MenuItem>
                <MenuItem value="Vi">Vi</MenuItem>
           
        </StyledSelect>
		  <InputAdornment
                    className={TabClasses.adornment}
                    position="end"
                  >
                    Change Operator
                  </InputAdornment>
		    </FormControl>
			
        

                    </Box>
                  </Grid>
				        <Grid item sm={6} md={3}  xs={12}>
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                      sx={{
                        "& > :not(style)": { width: "100%" },
                      }}
                    >
                      <StyledTextField
                        defaultValue=""
                        variant="filled"
                        label="Consumer Number"
                        InputProps={{disableUnderline: true}}
						 className={TabClasses.textField}
				
                      />
                    </Box>
                  </Grid>
                  <Grid item sm={6} md={3}  xs={12}>
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                      sx={{
                        "& > :not(style)": { width: "100%" },
                      }}
                    >
                      <StyledTextField
					  className={TabClasses.textField}
                    
                        variant="filled"
                        label="Amount"
						InputProps={{
                endAdornment: (
                  <InputAdornment
                    className={TabClasses.adornment}
                    position="end"
                  >
                    Fetch Bill
                  </InputAdornment>
                ),
				disableUnderline: true
              }}
			  
			
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
 <Grid container spacing={4} sx={{marginTop:"1px",justifyContent:"end"}} display={{ sm: "none", md: "flex" }}>
            <Grid item xs={12} sm={5} md={4} lg={3} sx={{paddingTop:"0"}} >


                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "primary.dark",
                    color: "#fff",
                    width: "100%",
                    height: "60px",
                  }}
                 
                >
                  Proceed to Pay
                </Button>
       
            </Grid> 
			   
     </Grid> 
	 
	 <Circle/>
	 	   <Faqs/>
           
    
          </TabPanel>

          <TabPanel value={value} index={1}>
                <Grid item xs={12} marginBottom="2rem">
              <Typography variant="h1">Quick Recharge</Typography>
              <Typography variant="h4">
                Get exciting cashback and offers
              </Typography>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={3}>
                  <Grid item sm={6} md={3}  xs={12}>
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                      sx={{
                        "& > :not(style)": { width: "100%" },
                      }}
                    >
                      <StyledTextField
                        defaultValue=""
                        variant="filled"
                        label="Enter Mobile Number"
                        InputProps={{disableUnderline: true}}
						 className={TabClasses.textField}
				
                      />
                    </Box>
                  </Grid>

                  <Grid item sm={6} md={3}  xs={12}>
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                      sx={{
                        "& > :not(style)": { width: "100%" },
                      }}
                    >
                					  
					  
					   <FormControl fullWidth variant="filled" className={TabClasses.selectControl}>
				
              <InputLabel id="demo-simple-select-label" sx={{fontSize:"16px"}} className="selectLabel">Select Circle</InputLabel>			  
			    <StyledSelect
				 	disableUnderline
				 className={TabClasses.selectbox}
				 SelectDisplayProps={{ style: { paddingTop: 45, paddingBottom: 10, fontWeight:500, fontSize: "18px", borderColor:'#91959E' } }}
				labelId="demo-simple-select-label"
                value={circle}
                label="Select Circle"
				onChange={handleCircleChange}
				inputProps={{
                endAdornment: (
                  <InputAdornment
                    className={TabClasses.adornment}
                    position="start"
                  >
                    Change Circle
                  </InputAdornment>
                )
              }}
			  
			  
				MenuProps={{
				sx: {
              "&& .Mui-selected": {
                backgroundColor: "#2341D5",
				color:"#fff"
              },
			   "&& .Mui-selected:hover": {
                backgroundColor: "#2341D5",
				color:"#fff"
              }
            },
			PaperProps: { sx: { maxHeight: 300,boxShadow: "0px 7px 25px rgba(117, 128, 137, 0.25)", borderRadius:"7px" } },
			
          }}
              >
			  	
                <MenuItem value="Kerala">Kerala</MenuItem>
                <MenuItem value="Karnataka">Karnataka</MenuItem>
				 <MenuItem value="Tamil">Tamil Nadu</MenuItem>
                <MenuItem value="Delhi">Delhi</MenuItem>
				 <MenuItem value="Madhya">Madhya Pradesh</MenuItem>
                <MenuItem value="Maharashtra">Maharashtra</MenuItem>
				 <MenuItem value="Andhra">Andhra Pradesh</MenuItem>
                <MenuItem value="Goa">Goa</MenuItem>
           
        </StyledSelect>
		  <InputAdornment
                    className={TabClasses.adornment}
                    position="end"
                  >
                    Change Circle
                  </InputAdornment>
            </FormControl>

                    </Box>
                  </Grid>

                  <Grid item sm={6} md={3}  xs={12}>
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                      sx={{
                        "& > :not(style)": { width: "100%" },
                      }}
                    >
                       <FormControl fullWidth variant="filled" className={TabClasses.selectControl}>
              <InputLabel id="demo-select-label" sx={{fontSize:"16px"}} className="selectLabel">
                Select Operator
              </InputLabel>
			     <StyledSelect
				 	disableUnderline
					inputProps={{
                endAdornment: (
                  <InputAdornment
                    className={TabClasses.adornment}
                    position="end"
                  >
                    Change Operator
                  </InputAdornment>
                )
              }}
				 className={TabClasses.selectbox}
				 SelectDisplayProps={{ style: { paddingTop: 45, paddingBottom: 10, fontWeight:500, fontSize: "18px", borderColor:'#91959E' } }}
				labelId="demo-select-label"
                id="demo-simple-select"
                value={operator}
				onChange={handleOperatorChange}
               
					MenuProps={{
					
				sx: {
              "&& .Mui-selected": {
                backgroundColor: "#2341D5",
				color:"#fff"
              },
			   "&& .Mui-selected:hover": {
                backgroundColor: "#2341D5",
				color:"#fff"
              }
            },
				
          }}
		  		
              >
                 <MenuItem value="Airtel">Airtel</MenuItem>
                <MenuItem value="Jio">Jio</MenuItem>
                <MenuItem value="Vi">Vi</MenuItem>
                <MenuItem value="Jio">Jio</MenuItem>
                <MenuItem value="Vi">Vi</MenuItem>
           
        </StyledSelect>
		  <InputAdornment
                    className={TabClasses.adornment}
                    position="end"
                  >
                    Change Operator
                  </InputAdornment>
		    </FormControl>
			
        

                    </Box>
                  </Grid>
                  <Grid item sm={6} md={3}  xs={12}>
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                      sx={{
                        "& > :not(style)": { width: "100%" },
                      }}
                    >
                      <StyledTextField
					  className={TabClasses.textField}
                        variant="filled"
                        label="Amount"
						InputProps={{
                endAdornment: (
                  <InputAdornment
                    className={TabClasses.adornment}
                    position="end"
                  >
                    Ftech Bill
                  </InputAdornment>
                ),
				disableUnderline: true
              }}
			  
			
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
   <NoDue/>
 
            <Grid container spacing={4} sx={{marginTop:"1px",justifyContent:"end"}} display={{ sm: "none", md: "flex" }}>
            <Grid item xs={12} sm={5} md={4} lg={3} sx={{paddingTop:"0"}} >


                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "primary.dark",
                    color: "#fff",
                    width: "100%",
                    height: "60px",
                  }}
                 
                >
                  Proceed to Pay
                </Button>
       
            </Grid> 
			   
     </Grid> 
	 <Operators/>
	   <Faqs/>
          </TabPanel>
        <TabPanel value={value} index={2}>
            <Grid item xs={12} marginBottom="2rem">
              <Typography variant="h1">Quick Recharge</Typography>
              <Typography variant="h4">
                Get exciting cashback and offers
              </Typography>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={3}>
              

                  <Grid item sm={6} md={3}  xs={12}>
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                      sx={{
                        "& > :not(style)": { width: "100%" },
                      }}
                    >
                					  
					  
					   <FormControl fullWidth variant="filled" className={TabClasses.selectControl}>
				
              <InputLabel id="demo-simple-select-label" sx={{fontSize:"16px"}} className="selectLabel">Select Circle</InputLabel>			  
			    <StyledSelect
				 	disableUnderline
				 className={TabClasses.selectbox}
				 SelectDisplayProps={{ style: { paddingTop: 45, paddingBottom: 10, fontWeight:500, fontSize: "18px", borderColor:'#91959E' } }}
				labelId="demo-simple-select-label"
                value={circle}
                label="Select Circle"
				onChange={handleCircleChange}
				inputProps={{
                endAdornment: (
                  <InputAdornment
                    className={TabClasses.adornment}
                    position="start"
                  >
                    Change Circle
                  </InputAdornment>
                )
              }}
			  
			  
				MenuProps={{
				sx: {
              "&& .Mui-selected": {
                backgroundColor: "#2341D5",
				color:"#fff"
              },
			   "&& .Mui-selected:hover": {
                backgroundColor: "#2341D5",
				color:"#fff"
              }
            },
			PaperProps: { sx: { maxHeight: 300,boxShadow: "0px 7px 25px rgba(117, 128, 137, 0.25)", borderRadius:"7px" } },
			
          }}
              >
			  	
                <MenuItem value="Kerala">Kerala</MenuItem>
                <MenuItem value="Karnataka">Karnataka</MenuItem>
				 <MenuItem value="Tamil">Tamil Nadu</MenuItem>
                <MenuItem value="Delhi">Delhi</MenuItem>
				 <MenuItem value="Madhya">Madhya Pradesh</MenuItem>
                <MenuItem value="Maharashtra">Maharashtra</MenuItem>
				 <MenuItem value="Andhra">Andhra Pradesh</MenuItem>
                <MenuItem value="Goa">Goa</MenuItem>
           
        </StyledSelect>
		  <InputAdornment
                    className={TabClasses.adornment}
                    position="end"
                  >
                    Change Circle
                  </InputAdornment>
            </FormControl>

                    </Box>
                  </Grid>
      <Grid item sm={6} md={3}  xs={12}>
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                      sx={{
                        "& > :not(style)": { width: "100%" },
                      }}
                    >
                      <StyledTextField
                        defaultValue=""
                        variant="filled"
                        label="Enter Mobile Number"
                        InputProps={{disableUnderline: true}}
						 className={TabClasses.textField}
				
                      />
                    </Box>
                  </Grid>
                  <Grid item sm={6} md={3}  xs={12}>
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                      sx={{
                        "& > :not(style)": { width: "100%" },
                      }}
                    >
                       <FormControl fullWidth variant="filled" className={TabClasses.selectControl}>
              <InputLabel id="demo-select-label" sx={{fontSize:"16px"}} className="selectLabel">
                Select Operator
              </InputLabel>
			     <StyledSelect
				 	disableUnderline
					inputProps={{
                endAdornment: (
                  <InputAdornment
                    className={TabClasses.adornment}
                    position="end"
                  >
                    Change Operator
                  </InputAdornment>
                )
              }}
				 className={TabClasses.selectbox}
				 SelectDisplayProps={{ style: { paddingTop: 45, paddingBottom: 10, fontWeight:500, fontSize: "18px", borderColor:'#91959E' } }}
				labelId="demo-select-label"
                id="demo-simple-select"
                value={operator}
				onChange={handleOperatorChange}
               
					MenuProps={{
					
				sx: {
              "&& .Mui-selected": {
                backgroundColor: "#2341D5",
				color:"#fff"
              },
			   "&& .Mui-selected:hover": {
                backgroundColor: "#2341D5",
				color:"#fff"
              }
            },
				
          }}
		  		
              >
                 <MenuItem value="Airtel">Airtel</MenuItem>
                <MenuItem value="Jio">Jio</MenuItem>
                <MenuItem value="Vi">Vi</MenuItem>
                <MenuItem value="Jio">Jio</MenuItem>
                <MenuItem value="Vi">Vi</MenuItem>
           
        </StyledSelect>
		  <InputAdornment
                    className={TabClasses.adornment}
                    position="end"
                  >
                    Change Operator
                  </InputAdornment>
		    </FormControl>
			
        

                    </Box>
                  </Grid>
				
                  <Grid item sm={6} md={3}  xs={12}>
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                      sx={{
                        "& > :not(style)": { width: "100%" },
                      }}
                    >
                      <StyledTextField
					  className={TabClasses.textField}
                       defaultValue="55"
                        variant="filled"
                        label="Amount"
						InputProps={{
                endAdornment: (
                  <InputAdornment
                    className={TabClasses.adornment}
                    position="end"
                  >
                    Browse Plans
                  </InputAdornment>
                ),
				disableUnderline: true
              }}
			  
			
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

       
            <Grid container spacing={4} sx={{marginTop:"1px",justifyContent:"end"}} display={{ sm: "none", md: "flex" }}>
            <Grid item xs={12} sm={5} md={4} lg={3} sx={{paddingTop:"0"}} >


                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "primary.dark",
                    color: "#fff",
                    width: "100%",
                    height: "60px",
                  }}
                 
                >
                  Proceed to Pay
                </Button>
       
            </Grid> 
			   
     </Grid> 
           <Plans/>
		   	 <Operators/>
	   <Faqs/>
          </TabPanel>
        </div>
		
    </Box>
  );
}
