import React, { ReactElement, FC } from "react";
import ModuleMobile from "../components/Modules/ModuleMobile";


const ModulesMobile: FC<any> = (): ReactElement => {
  return (
 <ModuleMobile/>
  );
};

export default ModulesMobile;