import React, { ReactElement, FC } from "react";
import { Box } from "@mui/material";

const About: FC<any> = (): ReactElement => {
  return (
   <>
    <Box>About</Box>
   </>
  );
};

export default About;