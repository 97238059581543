import React  from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Box, Typography } from "@mui/material";
import "./Faqs.css"


export default function Faqs() {
	
  return (
      <Box sx={{ width: "100%",marginTop: "2rem",marginBottom: "1rem" }} >
	  <h2 class="operator-heading">Frequently Asked Questions</h2>
	  <p>If you have any further questions please contact us</p>
      <Accordion>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h4"> 1.  When postpaid recharge payments are successful but the order status still shows 'pending'. How does a user confirm whether their postpaid bill
     payments are completed or not?
	 </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           Any postpaid bill payment recharge takes around 10 seconds to 20 minutes for completion. Kindly check once by visiting your transaction section for your respective order.
          </Typography>
        </AccordionDetails>
      </Accordion>
	  
	  
      <Accordion>
        <AccordionSummary
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
         <Typography variant="h4"> 2.  Is there a difference between a prepaid connection and a postpaid connection? What exactly is a postpaid recharge?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
