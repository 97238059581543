import React, { Component } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Slider from "react-slick";
import TestiClasses from "./Testimonial.module.css";
import "./Slick.css";
import StarIcon from "@mui/icons-material/Star";

export default class AsNavFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const settings = {
      dots: false,
      arrows:true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      swipeToSlide: true,
      focusOnSelect: true,
      autoplay: true,
      speed: 1500,
      autoplaySpeed: 4000,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
			arrows:true,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
			 arrows:true,
          }
        }
      ],
      beforeChange: function (currentSlide, nextSlide) {
        console.log("before change", currentSlide, nextSlide);
      },
      afterChange: function (currentSlide) {
        console.log("after change", currentSlide);
      },
    };
	
	
	    const settings2 = {
      responsive: [
        {
          breakpoint: 868,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
			arrows:true,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
			 arrows:true,
          }
        }
      ],
      beforeChange: function (currentSlide, nextSlide) {
        console.log("before change", currentSlide, nextSlide);
      },
      afterChange: function (currentSlide) {
        console.log("after change", currentSlide);
      },
    };

    return (
      <div>
	  
        <Box        
			paddingTop={{xs:"5%", md:"2%"}}
		
          sx={{
            width: "100%",
            height: "auto",
            background: "#f5f5f5",
			 position:"relative",
			 paddingBottom:"2%"
				
          }}
		  
		  	  
        >
		  
          <Container maxWidth="xl" sx={{ marginBottom: "1rem" }}>
		   
			    <Grid item xs={12} 
display={{ xs: 'block', sm: 'none' }}
			  >
                <Typography variant="h4" className={TestiClasses.heading}>
                  Customers love Payrup!
                </Typography>
                <p className={TestiClasses.sub}>
                  Get exciting cashback and offers
                </p>
              </Grid>
			  
            <div className={TestiClasses.Outerbox}>
              <Grid item xs={12} className={TestiClasses.headingdiv}
display={{ xs: 'none', sm: 'block' }}
			  >
                <Typography variant="h4" className={TestiClasses.heading}>
                  Customers love Payrup!
                </Typography>
                <p className={TestiClasses.sub}>
                  Get exciting cashback and offers
                </p>
              </Grid>

              <div className={TestiClasses.moduleTabs}>
                <Grid
                  container rowSpacing={2}
                >
                  <Grid item xs={12} sm={5} md={5} lg={4}
				display={{ xs: 'none', md: 'block' }}

				  >
                     <div class={TestiClasses.outerDiv}>
                    <Slider className="slider1"
                      asNavFor={this.state.nav2}
                      ref={(slider) => (this.slider1 = slider)}
                      {...settings}
                    >
                      <div >
                      <div class="testi-user">
                      <img
                        src="./assets/images/homepage/testi1.png"
                        alt="testi1"
                      />
                      <div>
                        <Typography variant="h2">Vasudevas devas</Typography>
                        <Typography variant="h5">Sales Manager, Teckborn</Typography>
                        </div>
                      </div>
                      </div>
                      <div>
                      <div class="testi-user">
                      <img
                        src="./assets/images/homepage/testi1.png"
                        alt="testi1"
                      />
                      <div>
                        <Typography variant="h2">Vasudevas devas</Typography>
                        <Typography variant="h5">Sales Manager, Teckborn</Typography>
                        </div>
                      </div>
                      </div>
                      <div>
                      <div class="testi-user">
                      <img
                        src="./assets/images/homepage/testi2.png"
                        alt="testi1"
                      />
                      <div>
                      <Typography variant="h2">Sundar das vallu</Typography>
                        <Typography variant="h5">Sales Manager, Teckborn</Typography>
                        </div>
                      </div>
                      </div>
                      <div>
                      <div class="testi-user">
                      <img
                        src="./assets/images/homepage/testi3.png"
                        alt="testi1"
                      />
                      <div>
                      <Typography variant="h2">Karthigeyat s</Typography>
                        <Typography variant="h5">Sales Manager, Teckborn</Typography>
                        </div>
                      </div>
                      </div>
                    </Slider>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={7} lg={8}>
                    <Slider
                      asNavFor={this.state.nav1}
                      ref={(slider) => (this.slider2 = slider)}
                      slidesToShow={1}
                      swipeToSlide={true}
                      focusOnSelect={true}
                      dots={false}
                      arrows={false}
                      className="contentSlider"
                      speed={1500}
					    {...settings2}
                    >
                      <div>
                      <Grid item xs={12} textAlign="left" 
					  marginBottom={{xs:"0%", md:"2%"}}
					  paddingTop={{xs:"0%", md:"2%"}}
					  
					  >
                    <Typography variant="h3">
                      It was Great Experience!
                    </Typography>
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <Typography variant="h5">
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text. It has roots in a piece of classical Latin
                      literature from 45 BC, making it over 2000 years old.
                      Richard McClintock, a Latin professor at Hampden-Sydney
                      College in Virginia, looked up one of the more obscure
                      Latin words Contrary to popular belief, Lorem Ipsum is not
                      simply random text.
                    </Typography>
				
                    <br />
                    <Typography variant="h5">
                      It has roots in a piece of classical Latin literature from
                      45 BC, making it over 2000 years old. Richard McClintock,
                      a Latin professor at Hampden-Sydney College in Virginia,
                      looked up one of the more obscure Latin words
                    </Typography>
						 <Grid display={{ xs: 'block', md: 'none' }}>
                      <div class="testi-user">
                      <img
                        src="./assets/images/homepage/testi1.png"
                        alt="testi1"
                      />
                      <div>
                        <Typography variant="h2">Vasudevas devas</Typography>
                        <Typography variant="h5">Sales Manager, Teckborn</Typography>
                        </div>
                      </div>
                      </Grid>
                  </Grid>
                      </div>
                      <div>
                      <Grid item xs={12} textAlign="left" 
					  marginBottom={{xs:"0%", md:"2%"}}
					  paddingTop={{xs:"0%", md:"2%"}}
					  
					  >
                    <Typography variant="h3">
                      It was Great Experience!
                    </Typography>
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <Typography variant="h5">
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text. It has roots in a piece of classical Latin
                      literature from 45 BC, making it over 2000 years old.
                      Richard McClintock, a Latin professor at Hampden-Sydney
                      College in Virginia, looked up one of the more obscure
                      Latin words Contrary to popular belief, Lorem Ipsum is not
                      simply random text.
                    </Typography>
                    <br />
                    <Typography variant="h5">
                      It has roots in a piece of classical Latin literature from
                      45 BC, making it over 2000 years old. Richard McClintock,
                      a Latin professor at Hampden-Sydney College in Virginia,
                      looked up one of the more obscure Latin words
                    </Typography>
					
					 <Grid display={{ xs: 'block', md: 'none' }}>
                      <div class="testi-user">
                      <img
                        src="./assets/images/homepage/testi1.png"
                        alt="testi1"
                      />
                      <div>
                        <Typography variant="h2">Vasudevas devas</Typography>
                        <Typography variant="h5">Sales Manager, Teckborn</Typography>
                        </div>
                      </div>
                      </Grid>
                  </Grid>
                      </div>
                      <div>
                         <Grid item xs={12} textAlign="left" 
					  marginBottom={{xs:"0%", md:"2%"}}
					  paddingTop={{xs:"0%", md:"2%"}}
					  
					  >
                    <Typography variant="h3">
                      It was Great Experience!
                    </Typography>
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <Typography variant="h5">
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text. It has roots in a piece of classical Latin
                      literature from 45 BC, making it over 2000 years old.
                      Richard McClintock, a Latin professor at Hampden-Sydney
                      College in Virginia, looked up one of the more obscure
                      Latin words Contrary to popular belief, Lorem Ipsum is not
                      simply random text.
                    </Typography>
                    <br />
                    <Typography variant="h5">
                      It has roots in a piece of classical Latin literature from
                      45 BC, making it over 2000 years old. Richard McClintock,
                      a Latin professor at Hampden-Sydney College in Virginia,
                      looked up one of the more obscure Latin words
                    </Typography>
					 <Grid display={{ xs: 'block', md: 'none' }}>
                      <div class="testi-user">
                      <img
                        src="./assets/images/homepage/testi1.png"
                        alt="testi1"
                      />
                      <div>
                        <Typography variant="h2">Vasudevas devas</Typography>
                        <Typography variant="h5">Sales Manager, Teckborn</Typography>
                        </div>
                      </div>
                      </Grid>
                  </Grid>
                      </div>
                      <div>
                       <Grid item xs={12} textAlign="left" 
					  marginBottom={{xs:"0%", md:"2%"}}
					  paddingTop={{xs:"0%", md:"2%"}}
					  
					  >
                    <Typography variant="h3">
                      It was Great Experience!
                    </Typography>
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <Typography variant="h5">
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text. It has roots in a piece of classical Latin
                      literature from 45 BC, making it over 2000 years old.
                      Richard McClintock, a Latin professor at Hampden-Sydney
                      College in Virginia, looked up one of the more obscure
                      Latin words Contrary to popular belief, Lorem Ipsum is not
                      simply random text.
                    </Typography>
                    <br />
                    <Typography variant="h5">
                      It has roots in a piece of classical Latin literature from
                      45 BC, making it over 2000 years old. Richard McClintock,
                      a Latin professor at Hampden-Sydney College in Virginia,
                      looked up one of the more obscure Latin words
                    </Typography>
					 <Grid display={{ xs: 'block', md: 'none' }}>
                      <div class="testi-user">
                      <img
                        src="./assets/images/homepage/testi1.png"
                        alt="testi1"
                      />
                      <div>
                        <Typography variant="h2">Vasudevas devas</Typography>
                        <Typography variant="h5">Sales Manager, Teckborn</Typography>
                        </div>
                      </div>
                      </Grid>
                  </Grid>
                      </div>
                    </Slider>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Container>
        </Box>
      </div>
    );
  }
}
