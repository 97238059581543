import React from "react";
import { Box, Grid } from "@mui/material";
import "./Operators.css";
const Operators = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "auto",          
          paddingTop: "1rem",
          paddingBottom: "1rem",
		  marginTop:"2rem"
        }}
        display={{ xs: "block", md: "block" }}
      >
   
		<h2 class="operator-heading">Select Postpaid Mobile Operators</h2>
              <Grid
            display={{ xs: "flex", md: "flex" }}
            
            direction="row"
            alignItems="center"
          >
            <Grid item className="box-shadow1">
         
			 
			 <img src="/assets/images/Operators/Mobile/airtel.png" alt="vi" />
			 
            </Grid>
			
		     <Grid item className="box-shadow1">
			    <img src="/assets/images/Operators/Mobile/bsnl.png" alt="vi" /> 
			       </Grid>
			
			     <Grid item className="box-shadow1">
			    <img src="/assets/images/Operators/Mobile/jio.png" alt="vi" />
		
            </Grid>
			
			     <Grid item className="box-shadow1">
			    <img src="/assets/images/Operators/Mobile/mtnl.png" alt="vi" /> 
		
            </Grid>
			
			     <Grid item className="box-shadow1">
			    <img src="/assets/images/Operators/Mobile/tata.png" alt="vi" /> 
			
            </Grid>
			
		     <Grid item className="box-shadow1">
			    <img src="/assets/images/Operators/Mobile/vi.png" alt="vi" /> 
			
            </Grid>
			 			
		
			           
          </Grid>
  
      </Box>

    </>
  );
};

export default Operators;
