import React from "react";
import NavbarClasses from "./Navbar.module.css";
import Slider from "./Slider";
import { Box, Container, Typography } from "@mui/material";
//import JsonIcon from "./JsonIcon";
import MainModule from './MainModule';


const Menu = ({ updateTab }: any) => {
  return (
    <>
      <Box
        position="relative"
        sx={{
          width: "100%",
          height: "auto",
        }}
      >
        <Slider />
        <Container maxWidth="xl">
          <div className={NavbarClasses.mainBannerHeading}>
            <Typography variant="h4">Everyday payments in <span>one place</span></Typography>
            <p>Get Cashback, Vouchers, and More</p>
          </div>

          <div className={NavbarClasses.mainBannerSection}>           
			
            <div className={NavbarClasses.container}>
              
              <div className={NavbarClasses.innerContainer}>
				
				  <MainModule/>
				
	  
              </div>
            </div>
          </div>

        
        </Container>
      
      </Box>
    </>
  );
};

export default Menu;
