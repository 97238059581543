import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Grid, Typography, Button } from "@mui/material";
import PlansClasses from "./MobilePlans.module.css";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function MobilePlans() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      position="relative"
      sx={{
        width: "100%",
        height: "auto",
        background: "#fbfbfb",
        paddingBottom: "2rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        borderRadius: "10px",
      }}
    >
        <Grid container className={PlansClasses.headingdiv} alignItems="center">
          <Grid item xs={1}>
            <img src="./assets/images/Icons/back-arrow.svg" alt="jio" />
          </Grid>

          <Grid item xs={11}>
            <Typography variant="h3" className={PlansClasses.heading}>
              Browse Plans
            </Typography>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
               
              }}
            >
              <img src="./assets/images/Icons/jio.png" alt="jio" />
              <Typography
                variant="body1"
                sx={{ marginLeft: "5px", fontWeight: "400" }}
              >
                Kerala
              </Typography>
            </Grid>
          </Grid>
        </Grid>

    

        <div className={PlansClasses.Outerbox}>
        
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs={12} md={2}>
				  <div className={PlansClasses.planTabs}>
                  <Tabs
                    value={value}
                    variant="scrollable"
                    onChange={handleChange}
                    aria-label="module tabs"
                    textColor="inherit"
                    className={PlansClasses.tabs}
                    TabIndicatorProps={{
                      style: { background: "#FAAF56" },
                    }}
                  >
                    <Tab label="Browse Plans" {...a11yProps(0)} />
                    <Tab label="Top up" {...a11yProps(1)} />
                    <Tab label="Data" {...a11yProps(2)} />
                    <Tab label="Full talktime" {...a11yProps(3)} />
                  </Tabs>
				      </div>
                </Grid>

                <Grid item xs={12} >
                  <TabPanel value={value} index={0}>
                    <div className={PlansClasses.planRow}>
					 <Grid container justifyContent="space-between">
                    <Grid item >
                      <h5>Talktime</h5>
                      <p>10</p>
                      </Grid>
                      <Grid item >
                      <h5>Validity</h5>
                      <p>3 Months</p>
                      </Grid>
                        <Grid item >
                          <Button>
								&#8377; 1799
                          </Button>
                      </Grid>
					   <Grid item xs={12}>
                      <h5>Description</h5>
                     <p> <span>There are many variations of passages of Lorem Ipsum available, but 
the majority have suffered alteration in some form, by</span>
</p>
                      </Grid>
					    </Grid>
                      </div>
					  
					  <div className={PlansClasses.planRow}>
					 <Grid container justifyContent="space-between">
                    <Grid item >
                      <h5>Talktime</h5>
                      <p>10</p>
                      </Grid>
                      <Grid item >
                       <h5>Validity</h5>
                      <p>3 Months</p>
                      </Grid>
                        <Grid item >
                          <Button>
								&#8377; 1799
                          </Button>
                      </Grid>
					   <Grid item xs={12}>
                      <h5>Description</h5>
                     <p> <span>There are many variations of passages of Lorem Ipsum available, but 
the majority have suffered alteration in some form, by</span>
</p>
                      </Grid>
					    </Grid>
                      </div>
					   <div className={PlansClasses.planRow}>
					 <Grid container justifyContent="space-between">
                    <Grid item >
                      <h5>Talktime</h5>
                      <p>10</p>
                      </Grid>
                      <Grid item >
                       <h5>Validity</h5>
                      <p>3 Months</p>
                      </Grid>
                        <Grid item >
                          <Button>
								&#8377; 1799
                          </Button>
                      </Grid>
					   <Grid item xs={12}>
                      <h5>Description</h5>
                     <p> <span>There are many variations of passages of Lorem Ipsum available, but 
the majority have suffered alteration in some form, by</span>
</p>
                      </Grid>
					    </Grid>
                      </div>
					  
					   <div className={PlansClasses.planRow}>
					 <Grid container justifyContent="space-between">
                    <Grid item >
                      <h5>Talktime</h5>
                      <p>10</p>
                      </Grid>
                      <Grid item >
                       <h5>Validity</h5>
                      <p>3 Months</p>
                      </Grid>
                        <Grid item >
                          <Button>
								&#8377; 1799
                          </Button>
                      </Grid>
					   <Grid item xs={12}>
                      <h5>Description</h5>
                     <p> <span>There are many variations of passages of Lorem Ipsum available, but 
the majority have suffered alteration in some form, by</span>
</p>
                      </Grid>
					    </Grid>
                      </div>
                       
                  </TabPanel>
                  <TabPanel value={value} index={1}></TabPanel>
                  <TabPanel value={value} index={2}></TabPanel>
                </Grid>
              </Grid>
            </Box>
          </div>
    
    </Box>
  );
}
