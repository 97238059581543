import * as React from "react";
import { styled } from "@mui/material/styles";

import {
  Box,
  Button,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import ProfileClasses from "./Profile.module.css";

export default function MyrefferBox() {
  
  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    borderRadius: "14px",
    boxShadow: "none"
  }));

   /*-----------------------------------------------*/
  
  return (
    <>
      <Item sx={{marginBottom: ['20px','30px','40px']}}>
        <Box className={ProfileClasses.profileDetails}>
          <Box className={ProfileClasses.refferalSet}>
            <Grid columns={{ xs: 12, md: 12 }} spacing={{ xs: 4, sm:4, md: 4 }} container>
              <Grid item md={5} sm={12} xs={12} container>
                <Typography variant="h3">Refer &amp; Earn</Typography>
                <Box className={ProfileClasses.refferalImg}> 
                  <img
                    src="/assets/images/profilepage/couple.svg"
                    alt="Refferal"
                  />
                </Box>
              </Grid>
              <Grid item md={7} sm={12} xs={12} container>
                <Box className={ProfileClasses.refferalBox}
                  sx={{background:"url(/assets/images/profilepage/starBack.png)"}}
                >
                  <Typography variant="h5">
                    You Have Earned 200 Points
                    <small>Invite a friend to Sign Up and Earn MyRupee</small>
                  </Typography>

                  <Box>
                    <Box id="copy-text" className={ProfileClasses.refferalInput}>
                      <TextField value="ABCD" InputProps={{
                        readOnly: true,
                        }}
                      />
                      <Button className={ProfileClasses.copyBtn}>
                        Copy Code
                      </Button>
                        
                     </Box>
                  </Box>

                  <Box>
                    <Box className={ProfileClasses.socialIcon}>
                      <Link href="#" underline="none">
                        <img
                          src="/assets/images/common/Facebook.svg"
                          alt="Facebook"
                        />
                      </Link>
                      <Link href="#" underline="none">
                        <img
                          src="/assets/images/common/Instagram.svg"
                          alt="Instagram"
                        />
                      </Link>
                      <Link href="#" underline="none">
                        <img
                          src="/assets/images/common/Twitter.svg"
                          alt="Twitter"
                        />
                      </Link>
                      <Link href="#" underline="none">
                        <img
                          src="/assets/images/common/Linkedin.svg"
                          alt="Linkedin"
                        />
                      </Link>


                    </Box>
                  </Box>

                </Box>
              </Grid>

            </Grid>
          </Box>
          
        </Box>
      </Item>

    </>
  );
}
