import React, { ReactElement, FC } from "react";

import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { East } from "@mui/icons-material";

import Sidebar from "../components/Profile/Sidebar";
import ProfileBox from "../components/Profile/ProfileBox";
import LoginBox from "../components/Profile/LoginBox";
import PhoneBox from "../components/Profile/PhoneBox";
import FavouriteBox from "../components/Profile/FavouriteBox";
import MyrupeeBox from "../components/Profile/MyrupeeBox";
import RefferBox from "../components/Profile/RefferBox";

import ProfileClasses from "./Profile.module.css";


const Profile: FC<any> = (): ReactElement => {
  const breadcrumbs = [
    <Link underline="hover" key="2" color="#111" href="/">
      Home
    </Link>,
    <Typography key="3" color="#586cd8">
      Profile
    </Typography>,
  ];


  return (
    <Box
      sx={{
        backgroundColor: "white",
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            flexGrow: 1,
            backgroundColor: "#edebfe",
            borderRadius: "12px",
            marginBottom: "15px",
            overflowX: "hidden",
          }}
        >
          <Container maxWidth="xl" sx={{marginTop:['25px',0,0]}}>
            <>
              <Stack spacing={1} className={ProfileClasses.breadcrumb}>
                <Breadcrumbs
                  separator={<East fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  {breadcrumbs}
                </Breadcrumbs>
              </Stack>
            </>

            <Grid
              container
              spacing={{ xs: 4, sm: 2 , md: 4, lg: 3}}
              columns={{ xs: 12, sm: 12, md: 12 ,lg: 12}}
              sx={{ padding: "0 15px 15px" }}
            >
              <Grid item lg={3} md={4} sm={12} xs={12}>
                <>
                  <Sidebar/>
                </>
              </Grid>

              <Grid item lg={9} md={8} sm={12} xs={12}>
                <>
                  <ProfileBox/>
                  <LoginBox/>
                  <PhoneBox/>
                  <FavouriteBox/>
                  <MyrupeeBox/>
                  <RefferBox/>
                </>
              </Grid>
              
            </Grid>
            
          </Container>
        </Box>
        
      </Container>
    </Box>

  );
};

export default Profile;
