import * as React from "react";
import Slick, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Styles.css";

class Slider extends React.PureComponent {
  handleBeforeChange = () => {
    console.log("%c handleBeforeChange", "color: blue");
  };

  handleAfterChange = () => {
    console.log("%c handleAfterChange", "color: red");
  };

  slickSettings: Settings = {
    lazyLoad: "ondemand",
    accessibility: false,
    draggable: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    beforeChange: this.handleBeforeChange,
    afterChange: this.handleAfterChange
  };


  renderItems = () => {
    const items = [
      {id:1, image:"homepage-banner.png"},
      {id:2, image:"homepage-banner.png"},
      {id:3, image:"homepage-banner.png"},
      {id:4, image:"homepage-banner.png"},
      {id:5, image:"homepage-banner.png"},
      {id:3, image:"homepage-banner.png"}
    ];


    return items.map((item:any) => (
      <div key={item.id}>
        <img src={`/assets/images/${item.image}`} alt="" />
      </div>
    ));
  };

  public render() {
    return (
      <div className="main-banner">
        <Slick {...this.slickSettings}>{this.renderItems()}</Slick>
      </div>
    );
  }
}

export default Slider;
