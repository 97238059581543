import React, { ReactElement, FC } from "react";
import MobilePlans from "../components/Modules/MobilePlans";


const PrepaidPlan: FC<any> = (): ReactElement => {
  return (
 <MobilePlans/>
  );
};

export default PrepaidPlan;