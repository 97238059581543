import * as React from "react";
import { styled } from "@mui/material/styles";

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import Modal from '@mui/material/Modal';
import ProfileClasses from "./Profile.module.css";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import PaginationControlled from "../pagination/pagination";

export default function PhoneBox() {
  
  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    borderRadius: "14px",
    boxShadow: "none"
  }));

  /*Phone Book Update Data*/

  function phoneBookData(name: string, email: string, phoneNo: string) {
    return { name, email, phoneNo };
  }

  const row = [
    phoneBookData("David Miller", "davidmiller09@gmail.com", "+91 987456321"),
    phoneBookData("David Miller", "davidmiller09@gmail.com", "+91 987456321"),
  ];
 
  /*------------------ Form -------------------*/  

  const [gender, setGender] = React.useState('');

  const handleChange1 = (event: SelectChangeEvent) => {
    setGender(event.target.value as string);
  };

  const dob = {
    startDate: "1990-01-01"
  };

  /*------------------ Modal JS -------------------*/
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  

  const [openAddFriend, friendMod] = React.useState(false);
  const addFriend = () => friendMod(true);
  const closeFriend = () => friendMod(false);
  

  /*-----------------------------------------------*/
  
  return (
    <>

      <Item sx={{marginBottom: ['20px','30px','40px']}}>
        <Box className={ProfileClasses.profileDetails}>
          <Button className={ProfileClasses.addFriends} onClick={addFriend}>
            <span>Add Friends</span>
            <img
              src="/assets/images/profilepage/edit2.svg"
              alt="Menu"
              className={ProfileClasses.profileEdit}
            />
          </Button>
          <Typography variant="h3">My PhoneBook</Typography>

          <Box>
            <TableContainer
              component={Paper}
              className={ProfileClasses.phoneTable}
            >
              <Table
                size="small"
              >
                <TableBody>
                  {row.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        "& td:nth-child(2)": { color: "#707070" },
                      }}
                    >
                      <TableCell align="left" sx={{ width: "50px" }}>
                        <img
                          src="/assets/images/profilepage/userBlue.svg"
                          alt="user"
                          className={ProfileClasses.phoneUser}
                        />
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">
                        {row.phoneNo}
                      </TableCell>
                      <TableCell align="right">
                        <Box  className={ProfileClasses.actionBtn}>
                          <Button className={ProfileClasses.cross}>
                            <img
                              src="/assets/images/profilepage/close.svg"
                              alt="Close"
                            />
                          </Button>
                          <Button>
                            <img
                              src="/assets/images/profilepage/pencil.svg"
                              alt="Edit"
                            />
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box>
              {/* <Typography align="right" sx={{color: "#faba3f", fontSize: "14px", margin: "6px 0 15px"}}>
                View More
              </Typography> */}
              <PaginationControlled/>
            </Box>
          
          </Box>
        </Box>
      </Item>

      <Modal
            open={openAddFriend}
            onClose={closeFriend}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
        <Box sx={style} className={ProfileClasses.modal}>
          <Button onClick={closeFriend} className={ProfileClasses.closeBtn}>
            <img
              src="/assets/images/profilepage/close.svg"
              alt="Menu"
              className={ProfileClasses.profileEdit}
            />
          </Button>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Add Friend Info
          </Typography>
          
          <Box className={ProfileClasses.modalForm}>
            <Grid columns={{ xs: 12, md: 12 }} spacing={{ xs: 2, sm:2, md: 2 }} container>
                <Grid item md={6} sm={6} xs={12} container>
                    <TextField
                      label="Full Name*"
                      placeholder="Enter Full Name"
                      color="primary"
                      type="text"
                      fullWidth
                    />
                </Grid>
                <Grid item md={6} sm={6} xs={12} container>
                    <TextField
                      label="Email*"
                      placeholder="Enter Email"
                      color="primary"
                      type="text"
                      fullWidth
                    />
                </Grid>
                <Grid item md={6} sm={6} xs={12} container>
                  <TextField
                    name=""
                    label="Date of Birth"
                    color="primary"
                    fullWidth
                    InputLabelProps={{ shrink: true, required: true }}
                    type="date"
                    defaultValue={dob.startDate}
                  />
                    
                </Grid>
                <Grid item md={6} sm={6} xs={12} container>
                    <TextField
                      label="Mobile Number*"
                      placeholder="Enter Mobile Number"
                      color="primary"
                      type="text"
                      fullWidth
                    />
                </Grid>
                <Grid item md={6} sm={6} xs={12} container>
                  <FormControl fullWidth>
                    <InputLabel>Gender</InputLabel>
                    <Select
                      value={gender}
                      label="Gender*"
                      onChange={handleChange1}
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={12} sm={12} xs={12} container>
                  <Typography variant="h6" sx={{fontSize:"16px"}}>
                    All Fields Required <span style={{color:"red"}}>*</span>
                  </Typography>
                </Grid>
                
                <Grid item md={12} sm={12} xs={12} container>

                    <Button variant="contained" fullWidth className={ProfileClasses.updateBtn}>
                      Add Friend
                    </Button>
                </Grid>

            </Grid>
          </Box>
        </Box>
      </Modal>

    </>
  );
}
