import * as React from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import PrepaidFormClasses from "./PrepaidForm.module.css";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import NoDue from "../NoDue/NoDue";


export default function PrepaidForm() {
  const [operator, setOperator] = React.useState("");
  const [circle, setCircle] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setOperator(event.target.value as string);
  };

  const handleCircleChange = (event: SelectChangeEvent) => {
    setCircle(event.target.value as string);
  }; 

  return (
    <Box
      position="relative"
      sx={{
        width: "100%",
        height: "auto",
        background: "#fbfbfb",
        paddingBottom: "2rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        borderRadius: "10px",
      }}
    >
      <Grid
        container
        className={PrepaidFormClasses.headingdiv}
        alignItems="center"
      >
        <Grid item xs={1}>
          <img src="./assets/images/Icons/back-arrow.svg" alt="Back Arrow" />
        </Grid>

        <Grid item xs={11}>
          <Typography variant="h3" className={PrepaidFormClasses.heading}>
            Postpaid Bill Payment
          </Typography>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          ></Grid>
        </Grid>
      </Grid>

      <div className={PrepaidFormClasses.Outerbox}>
        <Grid container spacing={2} className={PrepaidFormClasses.FormElements}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Enter Mobile Number"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Operator
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={operator}
                label="Select Operator"
                onChange={handleChange}
              >
                <MenuItem value="Airtel">Airtel</MenuItem>
                <MenuItem value="Jio">Jio</MenuItem>
                <MenuItem value="Vi">Vi</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Circle</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={circle}
                label="Circle"
                onChange={handleCircleChange}
              >
                <MenuItem value="Kerala">Kerala</MenuItem>
                <MenuItem value="Karnataka">Karnataka</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Amount"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    className={PrepaidFormClasses.adornment}
                    position="end"
                  >
                    Browse Plans
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              size="large"
              sx={{
                backgroundColor: "primary.dark",
                color: "#fff",
                height: "40px",
                textTransform: "capitalize",
                borderRadius: "7px",
              }}
            >
              Proceed to Pay
            </Button>
          </Grid>
        </Grid>
		
		  <NoDue/>

        <hr className={PrepaidFormClasses.hr}></hr>

        <div className={PrepaidFormClasses.recent}>
          <Grid item xs={12}>
            <Typography variant="h4">Recent Bill Payment</Typography>
          </Grid>

          <Grid container spacing={2} className={PrepaidFormClasses.recentList}>
            <Grid item xs={8}>
              <Typography variant="h5">99956332455</Typography>

              <Typography variant="h6">Last Bill Payment on 23 Jan 2022</Typography>
            </Grid>

            <Grid item xs={4}>
              <Button>&#8377; 1799</Button>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={PrepaidFormClasses.recentList}>
            <Grid item xs={8}>
              <Typography variant="h5">99956332455</Typography>

              <Typography variant="h6">Last Bill Payment on 23 Jan 2022</Typography>
            </Grid>

            <Grid item xs={4}>
              <Button>&#8377; 1799</Button>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={PrepaidFormClasses.recentList}>
            <Grid item xs={8}>
              <Typography variant="h5">99956332455</Typography>

              <Typography variant="h6">Last Bill Payment on 23 Jan 2022</Typography>
            </Grid>

            <Grid item xs={4}>
              <Button>&#8377; 1799</Button>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={PrepaidFormClasses.recentList}>
            <Grid item xs={8}>
              <Typography variant="h5">99956332455</Typography>

              <Typography variant="h6">Last Bill Payment on 23 Jan 2022</Typography>
            </Grid>

            <Grid item xs={4}>
              <Button>&#8377; 1799</Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Box>
  );
}
