import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Container, Grid, Typography } from "@mui/material";
import PlansClasses from "./Plans.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

function createData(
  amount: string,
  validity: string,
  data: string,
  description: string
) {
  return { amount, validity, data, description };
}

const rows = [
  createData(
    "239",
    "28 Days",
    "1.5 GB/Day",
    "Data: 1.5 GB/day | Voice: Unlimited Calls"
  ),
  createData(
    "239",
    "28 Days",
    "1.5 GB/Day",
    "Data: 1.5 GB/day | Voice: Unlimited Calls"
  ),
  createData(
    "239",
    "28 Days",
    "1.5 GB/Day",
    "Data: 1.5 GB/day | Voice: Unlimited Calls"
  ),
  createData(
    "239",
    "28 Days",
    "1.5 GB/Day",
    "Data: 1.5 GB/day | Voice: Unlimited Calls"
  ),
  createData(
    "239",
    "28 Days",
    "1.5 GB/Day",
    "Data: 1.5 GB/day | Voice: Unlimited Calls"
  ),
    createData(
    "239",
    "28 Days",
    "1.5 GB/Day",
    "Data: 1.5 GB/day | Voice: Unlimited Calls"
  ),
  createData(
    "239",
    "28 Days",
    "1.5 GB/Day",
    "Data: 1.5 GB/day | Voice: Unlimited Calls"
  ),
  createData(
    "239",
    "28 Days",
    "1.5 GB/Day",
    "Data: 1.5 GB/day | Voice: Unlimited Calls"
  ),
  createData(
    "239",
    "28 Days",
    "1.5 GB/Day",
    "Data: 1.5 GB/day | Voice: Unlimited Calls"
  ),
  createData(
    "239",
    "28 Days",
    "1.5 GB/Day",
    "Data: 1.5 GB/day | Voice: Unlimited Calls"
  ),
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Plans() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    
    <Box
      position="relative"
	  padding={{xs:"2rem 0", sm:"2rem 0rem", lg:"2rem 1rem 0rem 1rem"}}
      sx={{
        width: "100%",
        height: "auto",
        background: "#fbfbfb",
        marginTop:"2rem",
        borderRadius: "10px"
      }}
	  display={{ xs: 'none', md: 'block' }}
    >
      <Container maxWidth="xl" sx={{ marginBottom: "1rem" }} className="plansPage">
        <div className={PlansClasses.Outerbox}>
          <div className={PlansClasses.planTabs}>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
              }}
            >
              <Grid container >
                <Grid item xs={4} sm={4} md={2}>
                  <Grid item xs={12} className={PlansClasses.headingdiv}>
                    <Typography variant="h5" className={PlansClasses.heading}>
                      Browse Plans
                    </Typography>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <img src="./assets/images/Icons/jio.png" alt="jio" />
                      <Typography
                        variant="body1"
                        sx={{ marginLeft: "5px", fontWeight: "600" }}
                      >
                        Jio
                      </Typography>
                    </Grid>
                  </Grid>
                  <Tabs
                    orientation="vertical"
                    // variant="scrollable"
                    value={value}
                    TabIndicatorProps={{ style: { background: "#FAAF56", margin:"0 0px",left:"-2.5px",width: "4px", borderRadius: "50px", height: "20px" } }}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderLeft: 1, borderColor: "divider", overflow:"visible" }}
					className={PlansClasses.Tabss}
                  >
                    <Tab
                      iconPosition="start"
                      disableRipple
                      label={
                        <div>
                          <Typography variant="h6">Popular</Typography>
                        </div>
                      }
                      icon={
                        <img
                          src="./assets/images/plans/popular.svg"
                          alt="testi1"
                        />
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      iconPosition="start"
                      disableRipple
                      label={
                        <div>
                          <Typography variant="h6">Work from Home</Typography>
                        </div>
                      }
                      icon={
                        <img
                          src="./assets/images/plans/home.svg"
                          alt="testi2"
                        />
                      }
                      {...a11yProps(1)}
                    />
                    <Tab
                      iconPosition="start"
                      disableRipple
                      label={
                        <div>
                          <Typography variant="h6">Cricket Packs</Typography>
                        </div>
                      }
                      icon={
                        <img
                          src="./assets/images/plans/cricket.svg"
                          alt="testi3"
                        />
                      }
                      {...a11yProps(2)}
                    />
                    <Tab
                      iconPosition="start"
                      disableRipple
                      label={
                        <div>
                          <Typography variant="h6">Smart Phone</Typography>
                        </div>
                      }
                      icon={
                        <img
                          src="./assets/images/plans/smartphone.svg"
                          alt="testi3"
                        />
                      }
                      {...a11yProps(2)}
                    />

                    <Tab
                      iconPosition="start"
                      disableRipple
                      label={
                        <div>
                          <Typography variant="h6">Jio Phone</Typography>
                        </div>
                      }
                      icon={
                        <img
                          src="./assets/images/plans/jiophone.svg"
                          alt="testi3"
                        />
                      }
                      {...a11yProps(2)}
                    />

                    <Tab
                      iconPosition="start"
                      disableRipple
                      label={
                        <div>
                          <Typography variant="h6">Talktime</Typography>
                        </div>
                      }
                      icon={
                        <img
                          src="./assets/images/plans/talktime.svg"
                          alt="testi3"
                        />
                      }
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </Grid>

                <Grid item xs={8} sm={8} md={10} sx={{ paddingLeft: "1rem" }}>
                  <TabPanel value={value} index={0}>
                    <TableContainer className={PlansClasses.tableDiv}>
					 <Table /*stickyHeader*/ aria-label="sticky table" className={PlansClasses.plansTable}>
					 <TableHead>
                          <TableRow>
                            <TableCell>Price</TableCell>
                            <TableCell align="center">Validity</TableCell>
                            <TableCell align="left">Data</TableCell>
                            <TableCell align="left" colSpan={2}>Description</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <>
                            <TableRow sx={{ "td, th": { border: 0 } }}>
                            <TableCell
                                  className={PlansClasses.amount}
                                  rowSpan={2}
                                >
                                  &#8377; {row.amount}
                                </TableCell>
                                <TableCell align="left" colSpan={5} className={PlansClasses.extra}>
                                  Extra Validity
                                </TableCell>
                              </TableRow>
                              <TableRow
                                key={row.amount}
                                sx={{ "td, th": { border: 0 } }}
                              >
                                
                                <TableCell align="center">
                                  {row.validity}
                                </TableCell>
                                <TableCell align="left">{row.data}</TableCell>
                                <TableCell align="left">
                                  {row.description}
                                </TableCell>
                                <TableCell align="right" className={PlansClasses.buyPack}>
                                  <button>Buy Pack</button>
                                </TableCell>
                              </TableRow>
                              
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
					<Grid textAlign="center" justifyContent="center">
					<Grid sx={{background:"#fff",borderRadius:"50%",boxShadow: "0px 0px 8px rgba(145, 149, 158, 0.15)", margin:"20px auto 0px",width:"30px",height:"30px",cursor:"pointer",lineHeight: "30px"}}>
					<img src="/assets/images/Icons/down-arrow.svg" alt=""/>
					</Grid>
					</Grid>
					
                  </TabPanel>
                  <TabPanel value={value} index={1}></TabPanel>
                  <TabPanel value={value} index={2}></TabPanel>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </Container>
    </Box>
  );
}
