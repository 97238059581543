import React, { ReactElement, FC } from "react";

import ModuleMobile from "../components/Modules/ModuleMobile";
import PrepaidForm from "../components/Modules/PrepaidForm";
import MobilePlans from "../components/Modules/MobilePlans";

const Prepaid: FC<any> = (): ReactElement => {
  return (
    <>
   <ModuleMobile/>
   <PrepaidForm/>
   <MobilePlans/>
   </>
  );
};

export default Prepaid;