import React, { ReactElement, FC } from "react";
import { Box } from "@mui/material";
import Offers from "../components/offers/Offers";
import Menu from "../components/Header/Menu";
import Know from "../components/Homepage/Know";
import Slider from "../components/Homepage/Slider";
import TestiSlider from "../components/Homepage/TestiSlider";
import CustomerSupport from "../components/Homepage/CustomerSupport";

const Home: FC<any> = (): ReactElement => {
  return (
    <Box
    >
      <Menu/>
	  <Slider/>
      <Offers/>
      <Know/>
        <TestiSlider/>
        <CustomerSupport/>
    </Box>
  );
};

export default Home;