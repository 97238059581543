import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import MobileModules from "./ModuleMobile.module.css";

export default function MobilePlans() {

const data = [
  { id: 1, heading: "Mobile", subHeading: "Prepaid", image: "mobile-prepaid.svg" },
  { id: 2, heading: "Mobile", subHeading: " Postpaid", image: "mobile-postpaid.svg" },
  { id: 3, heading: "Dth", subHeading: " Recharge", image: "dth-tv.svg" },

];

  return (
    <Box
      position="relative"
      sx={{
        width: "100%",
        height: "auto",
        background: "#fbfbfb",
        paddingBottom: "2rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        borderRadius: "10px",
      }}
    >
        <Grid container className={MobileModules.headingdiv} alignItems="center">
          <Grid item xs={1}>
            <img src="./assets/images/Icons/back-arrow.svg" alt="jio" />
          </Grid>

          <Grid item xs={11}>
            <Typography variant="h3" className={MobileModules.heading}>
              Mobile Prepaid
            </Typography>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
               
              }}
            >
            </Grid>
          </Grid>
        </Grid>

    

        <div className={MobileModules.Outerbox}>


          <div className={MobileModules.mainBannerSection}>           
			
            <div className={MobileModules.container}>
              <div className={MobileModules.innerContainer}>
                {data.map((item, index) => (
                  <div
                    tabIndex={-1}
                    key={item.id}
                    className={MobileModules.tabHeading}
                  >
                    <Grid container alignItems="center" justifyContent="center" className={MobileModules.innerBox}>
                      <Grid item className={MobileModules.imageGrid}>
                        <img
                          src={`/assets/images/Icons/${item.image}`}
                          alt={item.heading}
                        />
                      </Grid>
                      <Grid item  display="inline-table" alignSelf="center">
                        <Typography variant="h4">{item.heading}</Typography>
                        <span>{item.subHeading}</span>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </div>
            </div>
          </div>
           
          </div>
    
    </Box>
  );
}
