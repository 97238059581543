import React from "react";
import { Box, Grid } from "@mui/material";
import circleClasses from "./Circle.module.css";
const Circle = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "auto",          
          paddingTop: "1rem",
          paddingBottom: "1rem",
		  marginTop:"2rem"
        }}
        display={{ xs: "block", md: "block" }}
      >
	
	  <div className={circleClasses.box}>
	    <Grid item xs={12}>
     <h2 class="operator-heading">Select Electricity Circle</h2>
		    </Grid>

            <Grid item className={circleClasses.boxshadow}>
         
			 
			Andra Pradesh Bill Payment
			 
            </Grid>
			
		     <Grid item className={circleClasses.boxshadow}>
			  Assam Bill Payment
			       </Grid>
			
			     <Grid item className={circleClasses.boxshadow}>
		Bihar Bill Payment
		
            </Grid>
			
			     <Grid item className={circleClasses.boxshadow}>
			 Chandigarh Bill Payment
		
            </Grid>
			
			     <Grid item className={circleClasses.boxshadow}>
			Chhattisgarh Bill Payment
			
            </Grid>
			
		     <Grid item className={circleClasses.boxshadow}>
			Dada and Nagar Haveli Bill Payment
			
            </Grid>
			
			   <Grid item className={circleClasses.boxshadow}>
			Daman and Diu Bill Payment
			
            </Grid>
			 			
		
	
  </div>
      </Box>

    </>
  );
};

export default Circle;
