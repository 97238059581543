import * as React from "react";
import { styled } from "@mui/material/styles";

import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

import ProfileClasses from "./Profile.module.css";

export default function FavouriteBox() {
  
  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    borderRadius: "14px",
    boxShadow: "none"
  }));

  /*Favourites Update Data*/

  function favouriteData(image: string, description: string) {
    return { image, description};
  }

  const row = [
    favouriteData("fav2.svg", "South Bihar Power Distribution 9874563210"),
    favouriteData("fav2.svg", "South Bihar Power Distribution 9874563210"),
    favouriteData("fav1.svg", "Paid for order at Payrup Jio MobileRecharge"),
    favouriteData("fav1.svg", "Paid for order at Payrup Jio MobileRecharge"),
  ];


  /*-----------------------------------------------*/
  
  return (
    <>
      <Item sx={{marginBottom: ['20px','30px','40px']}}>
        <Box className={ProfileClasses.profileDetails}>
          <Typography variant="h3">Favourites</Typography>

          <Box>
            <TableContainer
              component={Paper}
              className={ProfileClasses.favouriteTable}
            >
              <Table
                size="small"
                aria-label="a dense table"
              >
                <TableBody>
                  {row.map((row) => (
                    <TableRow
                      sx={{
                        "& td:nth-child(1)": { borderRadius: "6px 0 0 6px" },
                        "& td:nth-child(4)": { borderRadius: "0 6px 6px 0" },
                        "@media only screen and (max-width: 768px)": {
                          "& td:nth-child(4)": { 
                            display:"none",
                          }
                          
                        }
                      }}
                      >
                      <TableCell align="left" sx={{ width: "70px" }} className={ProfileClasses.operatorImg}>
                        <img
                          src={"/assets/images/profilepage/" + row.image}
                          alt="user"
                          className={ProfileClasses.image}
                        />
                      </TableCell>
                      <TableCell align="left">
                        {row.description}
                      </TableCell>
                      <TableCell align="right">
                        <Button className={ProfileClasses.payNow}>
                            Pay Now
                        </Button>
                      </TableCell>
                      <TableCell align="right"  sx={{ width: "60px" }} >
                        <Button className={ProfileClasses.cross}>
                          <img
                            src="/assets/images/profilepage/close.svg"
                            alt="Close"
                          />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Item>

    </>
  );
}
