import React, { ReactElement, FC } from "react";
import ElectricityForm from "../components/Modules/ElectricityForm";


const Prepaid: FC<any> = (): ReactElement => {
  return (
    <>

 <ElectricityForm/>
 
   </>
  );
};

export default Prepaid;