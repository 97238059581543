import React, { ReactElement, FC } from "react";

import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";

import Modal from '@mui/material/Modal';
import OTP from "../Signin/Otpmodal";

import SigninClasses from "./Signin.module.css";

export const Signin: FC = (): ReactElement => {

  const inputStyle = {
      width: "80%",
      "& .MuiOutlinedInput-root": {
          "& fieldset": { 
              borderRadius: "6px",
          },
      "&.Mui-focused fieldset": {
          borderColor: "#586cd8"
      }
    }
  }

  /*------------------ Modal JS -------------------*/
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
  /*------------------Modal----------------*/

  const [openSignin, signinMod] = React.useState(false);

  const [openOtp, OtpMod] = React.useState(false);
  const closeOtpMod = () => OtpMod(false);

  /*---------------------------------------*/

  return(
      <>
        

        <Box className={SigninClasses.modalForm}>
          <Grid
            columns={{ xs: 12, md: 12 }}
            spacing={{ xs: 2, sm: 2, md: 2 }}
            container
          >
            <Grid item md={5} sm={5} xs={12} container>
              <img
                src="/assets/images/signin/signin3.svg"
                alt="signin Img"
                className={SigninClasses.signinImg}
              />
            </Grid>
            <Grid item md={7} sm={7} xs={12} container>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                className={SigninClasses.title}
              >
                Sign in to Payrup
              </Typography>

              <TextField
                className={SigninClasses.inputRes}
                sx={inputStyle}
                label="Enter Your Mobile Number"
                placeholder=""
                color="primary"
                type="text"
                helperText=""
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91-</InputAdornment>
                  ),
                }}
              />

              <FormGroup className={SigninClasses.terms}>
                <FormControlLabel control={<Checkbox />} label="I agree to the " />
                <Link href="#"> Terms &amp; Conditions</Link>
              </FormGroup>

              <Button
                variant="contained"
                fullWidth
                className={SigninClasses.updateBtn}
                onClick={() => { OtpMod(true); }}
              >
                Get OTP
              </Button>

              <Typography
                className={SigninClasses.hassle}
                variant="h6"
                align="right"
              >
                Your journey to hassle free utility payments begins here.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        

        <Modal
          open={openOtp}
          onClose={closeOtpMod}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        > 
          <Box sx={style} className={SigninClasses.modal}>
            <Button onClick={closeOtpMod} className={SigninClasses.closeBtn}>
              <img src="/assets/images/signin/close.png" alt="close" />
            </Button>
            
            <OTP />

          </Box>
        </Modal>

      </>

  );
};

export default Signin;
