import * as React from "react";
import { styled } from "@mui/material/styles";

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import Modal from '@mui/material/Modal';
import ProfileClasses from "./Profile.module.css";
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function ProfileBox() {
  
  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    borderRadius: "14px",
    boxShadow: "none"
  }));

 
  /*------------------ Form -------------------*/  
  const [gender, setGender] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setGender(event.target.value as string);
  };

  const dob = {
    startDate: "1990-01-01"
  };

  /*------------------ Modal JS -------------------*/
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  

  const [openProfile, profileMod] = React.useState(false);
  const profileUpdate = () => profileMod(true);
  const closeProfile = () => profileMod(false);
  

  /*-----------------------------------------------*/
  
  return (
    <>

      <Item sx={{marginBottom: ['20px','30px','40px']}}>
        <Box className={ProfileClasses.profileDetails}>
          <Button onClick={profileUpdate}>
            <img
              src="/assets/images/profilepage/pencil.svg"
              alt="Menu"
              className={ProfileClasses.profileEdit}
            />
          </Button>
           

          <Typography variant="h3">Profile</Typography>

          <List className={ProfileClasses.profileDataList}>
            <ListItem>
              <ListItemText primary="Name" secondary="David Miller" />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Date of Birth"
                secondary="02 August 1998"
              />
            </ListItem>

            <ListItem>
              <ListItemText primary="Gender" secondary="Male" />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Address"
                secondary="4th Floor, Plot No.22, Above Public Park"
              />
            </ListItem>

            <ListItem>
              <ListItemText primary="State" secondary="Karnataka" />
            </ListItem>

            <ListItem>
              <ListItemText primary="City" secondary="Banglore" />
            </ListItem>

            <ListItem>
              <ListItemText primary="Pincode" secondary="560988" />
            </ListItem>
          </List>
        </Box>
      </Item>  
      
      <Modal
          open={openProfile}
          onClose={closeProfile}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={ProfileClasses.modal}>
            <Button onClick={closeProfile} className={ProfileClasses.closeBtn}>
              <img
                src="/assets/images/profilepage/close.svg"
                alt="Menu"
                className={ProfileClasses.profileEdit}
              />
            </Button>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              Update Profile
            </Typography>
            
            <Box className={ProfileClasses.modalForm}>
              <Grid columns={{ xs: 12, md: 12 }} spacing={{ xs: 2, sm:2, md: 2 }} container>
                  <Grid item md={6} sm={6} xs={12} container>
                      <TextField
                        label="Full Name*"
                        placeholder="Enter Full Name"
                        color="primary"
                        type="text"
                        fullWidth
                      />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} container>
                      <TextField
                        label="Pincode*"
                        placeholder="Enter Pincode"
                        color="primary"
                        type="text"
                        fullWidth
                      />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} container>
                    <TextField
                      name=""
                      label="Date of Birth"
                      color="primary"
                      fullWidth
                      InputLabelProps={{ shrink: true, required: true }}
                      type="date"
                      defaultValue={dob.startDate}
                    />
                      
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} container>
                      <TextField
                        label="City*"
                        placeholder="Enter City"
                        color="primary"
                        type="text"
                        fullWidth
                      />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} container>
                    <FormControl fullWidth>
                      <InputLabel>Gender</InputLabel>
                      <Select
                        value={gender}
                        label="Gender*"
                        onChange={handleChange}
                      >
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} container>
                      <TextField
                        label="State*"
                        placeholder="Enter State"
                        color="primary"
                        type="text"
                        fullWidth
                      />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12} container>
                      <TextField
                        label="Address*"
                        placeholder="Enter Address"
                        color="primary"
                        type="text"
                        fullWidth
                      />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12} container>
                      <Button variant="contained" fullWidth className={ProfileClasses.updateBtn}>
                        Update
                      </Button>
                  </Grid>

              </Grid>
            </Box>
          </Box>
        </Modal> 
    </>
  );
}
