import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import PaginationClasses from "./Pagination.module.css";

const styles = {
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: '#586cd8',
        color: '#fff',
        borderRadius:'4px',
        boxShadow:'0px 2px 4px -1px #1f1e1e',
      },
    },
  },
};
export default function PaginationControlled() {
  const [page, setPage] = React.useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Stack spacing={2}>
      <Pagination count={4} page={page} onChange={handleChange} sx={styles} className={PaginationClasses.box} />
    </Stack>
  );
}
