// pages
import Home from "./pages/Home";
import About from "./pages/About";
import Modules from "./pages/Modules";
import Profile from "./pages/Profile";
import Prepaid from "./pages/Prepaid";
import Postpaid from "./pages/Postpaid";
import Dth from "./pages/Dth";
import Electricity from "./pages/Electricity";
import PrepaidPlan from "./pages/PrepaidPlan";
import More from "./pages/More";

// other
import {FC} from "react";

// interface
interface Route {
    key: string,
    title: string,
    path: string,
    enabled: boolean,
    component: FC<{}>
}

export const routes: Array<Route> = [
    {
        key: 'home-route',
        title: 'Home',
        path: '/',
        enabled: true,
        component: Home
    },
    {
        key: 'about-route',
        title: 'About',
        path: '/about',
        enabled: true,
        component: About
    },
	{
        key: 'mobile-plans-route',
        title: 'Plan',
        path: '/plan',
        enabled: true,
        component: PrepaidPlan
    },
	
    {
        key: 'module-route',
        title: 'Modules',
        path: '/modules',
        enabled: true,
        component: Modules
    },
    {
        key: 'profile-route',
        title: 'Profile',
        path: '/profile',
        enabled: true,
        component: Profile
    },
    {
        key: 'prepaid-route',
        title: 'Prepaid',
        path: '/prepaid',
        enabled: true,
        component: Prepaid
    },
	{
        key: 'postpaid-route',
        title: 'Postpaid',
        path: '/postpaid',
        enabled: true,
        component: Postpaid
    },
	 {
        key: 'dth-route',
        title: 'Dth',
        path: '/dth',
        enabled: true,
        component: Dth
    },
	 {
        key: 'electricity-route',
        title: 'Electricity',
        path: '/electricity',
        enabled: true,
        component: Electricity
    },
	{
        key: 'prepaid-more-route',
        title: 'More',
        path: '/more',
        enabled: true,
        component: More
    },
]