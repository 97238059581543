import React from "react";
import { styled } from "@mui/material/styles";
import NavbarClasses from "./Navbar.module.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Signin from "../Signin/Signin";
import SigninClasses from "../Signin/Signin.module.css";

import Modal from '@mui/material/Modal';

import { Box, Button, Link, Container, Typography, Grid } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menus from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import ListItemText from '@mui/material/ListItemText';

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "6px",
  backgroundColor: "#F4F5F7",
  marginLeft: 0,
  width: "100%",
  border: "1px solid #f5f5f5",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.4, 0, 1.4, 1.5),
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "24ch",
      "&:focus": {
        width: "30ch",
      },
    },
  },
}));

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const Navbar = ({ updateTab }: any) => {

/*------------------Modal----------------*/

  const [openSignin, signinMod] = React.useState(false);
  const signinUpdate = () => signinMod(true);
  const closeSignin = () => signinMod(false);

/*---------------------------------------*/
 const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          backgroundColor: "primary.light",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
        display={{ xs: "none", md: "block" }}
      >
        <Container maxWidth="xl" className={NavbarClasses.topheader}>
          <Grid
            container
            direction="row"
            alignItems="right"
            justifyContent="flex-end"
          >
            <Link alignSelf="center" 
			 id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
		>
              Eng
              <ArrowDropDownIcon />
            </Link>
				
			   <Menus
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
		className={NavbarClasses.moreMenu}
		sx={{height:350}}
      >
      
		  <MenuItem onClick={handleClose}>

          <ListItemText>English</ListItemText>
        </MenuItem>
		 <MenuItem onClick={handleClose}>

          <ListItemText>Kannada</ListItemText>
        </MenuItem>
		 <MenuItem onClick={handleClose}>

          <ListItemText>Hindi</ListItemText>
        </MenuItem>
		 <MenuItem onClick={handleClose}>

          <ListItemText>Malyalam</ListItemText>
        </MenuItem>

		 
      </Menus>
	  
	  
            <Link
              sx={{
                marginLeft: "1rem",
              }}
            >
              Blog
            </Link>
            <Link
              sx={{
                marginRight: "1rem",
                marginLeft: "1rem",
              }}
            >
              Crypto
            </Link>
            <Link>Support</Link>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <Container maxWidth="xl">
          <Grid
            display={{ xs: "flex", md: "none" }}
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item alignSelf="center">
              <img src="/assets/images/menu.svg" alt="Menu" />
            </Grid>
            <Grid item alignSelf="center">
              <img
                className={NavbarClasses.payruplogo}
                src="/assets/images/Logo/payrup-logo.png"
                alt="Payrup Logo"
              />
            </Grid>
            <Grid item alignSelf="center"  className={NavbarClasses.rightIcons}
                      sx={{cursor:"pointer"}}>
					 
					   <img src="/assets/images/Icons/search-icon.svg" alt="search" />
					   <img src="/assets/images/Icons/bell.png" alt="Notification" />
					   
              <img  onClick={signinUpdate} src="/assets/images/Icons/profile-small.svg" alt="My Profile" />
            </Grid>
          </Grid>
          <Grid
            display={{ xs: "none", md: "flex" }}
            container
            direction="row"
            alignItems="right"
            justifyContent="space-between"
          >
            <Grid item alignSelf="center">
              <img src="/assets/images/menu.svg" alt="Menu" />
              <img
                className={NavbarClasses.payruplogo}
                src="/assets/images/Logo/payrup-logo.png"
                alt="Payrup Logo"
              />
            </Grid>

            <Grid item>
              <Grid
                container
                direction="row"
                alignItems="right"
                justifyContent="flex-end"
              >
                <Grid item>
                  <Search>
                    <StyledInputBase
                      placeholder="Search"
                      inputProps={{ "aria-label": "search" }}
                    />
                    <img
                      src="/assets/images/Icons/search.svg"
                      alt="Search"
                      className={NavbarClasses.search}
                    />
                    <img
                      src="/assets/images/Icons/mic.svg"
                      alt="Mic"
                      className={NavbarClasses.mic}
                    />
                  </Search>
                </Grid>

                <Grid item>
                  <div className={NavbarClasses.box}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <img
                          src="/assets/images/Icons/my-rupee.svg"
                          alt="My Rupee"
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="h5">My Rupee</Typography>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          Check your Earnings!
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid item>
                  <div className={NavbarClasses.box}>
                    <Grid container alignItems="center" justifyContent="center" 
                      onClick={signinUpdate}
                      sx={{cursor:"pointer"}}
                    >
                      <Grid item>
                        <img
                          src="/assets/images/Icons/profile.svg"
                          alt="My Rupee"
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="h5">Sign In</Typography>
                      </Grid>
                      <Grid item>
                        <KeyboardArrowDownIcon sx={{ marginTop: "8px" }} />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid item alignSelf="center" marginLeft="1rem" className={NavbarClasses.notification}>
                  <img
                    src="/assets/images/notification-icon.svg"
                    alt="My Rupee"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Container>
        <>

        <Modal
          open={openSignin}
          onClose={closeSignin}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={SigninClasses.modal}>
            <Button onClick={closeSignin} className={SigninClasses.closeBtn}>
              <img src="/assets/images/signin/close.png" alt="close" />
            </Button>

            <Signin />

          </Box>
        </Modal>
        </>
      </Box>
    </>
  );
};

export default Navbar;
