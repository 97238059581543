import * as React from "react";
import { styled } from "@mui/material/styles";

import {
  Avatar,
  Box,
  Button,
  List,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";

import ProgressBar from "react-customizable-progressbar";
import ProfileClasses from "./Profile.module.css";


export default function Sidebar() {

  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    borderRadius: "14px",
    boxShadow: "none",      
  }));

  return (
    <>
      <Item sx={{marginBottom: ['20px','30px','40px']}} className={ProfileClasses.profileBox}>
        <Box className={ProfileClasses.profileImgBox}>
          <Box className={ProfileClasses.profileImgMobile}>
            <Box className={ProfileClasses.userImg}>
              <img
                src="/assets/images/profilepage/user.png"
                alt="Menu"
                className={ProfileClasses.profileImg}
              />
              <Button className={ProfileClasses.updateImg}>
                <img
                  src="/assets/images/profilepage/camera.svg"
                  alt="Profile"
                />
              </Button>
            </Box>
            <Box className={ProfileClasses.members}>
              <Typography variant="h4">David Miller</Typography>
              <Typography variant="h5" sx={{ color: "#222" }}>
                Member Since
              </Typography>
              <Typography variant="h5" sx={{ color: "#222" }}>
                10 January 2022
              </Typography>
            </Box>
          </Box>
          <Box className={ProfileClasses.sideMenu}>
            <List
              sx={{ margin: "15px -7.5px" }}
              className={ProfileClasses.menuList}
            >
              <ListItemButton
                component="a"
                href="#"
                className={ProfileClasses.active}
              >
                <small></small>
                <ListItemIcon className={ProfileClasses.sideIcon}>
                  <Avatar
                    alt=""
                    src="/assets/images/profilepage/userBlue.svg"
                  />

                </ListItemIcon>
                <ListItemText
                  primary="Profile"
                  className={ProfileClasses.menuTitle}
                />
              </ListItemButton>

              <ListItemButton component="a" href="#">
                <small></small>
                <ListItemIcon className={ProfileClasses.sideIcon}>
                  <Avatar
                    alt=""
                    src="/assets/images/profilepage/logIcon.svg"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Login Details"
                  className={ProfileClasses.menuTitle}
                />
              </ListItemButton>

              <ListItemButton component="a" href="#">
                <small></small>
                <ListItemIcon className={ProfileClasses.sideIcon}>
                  <Avatar
                    alt=""
                    src="/assets/images/profilepage/phone.svg"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="My PhoneBook"
                  className={ProfileClasses.menuTitle}
                />
              </ListItemButton>

              <ListItemButton component="a" href="#">
                <small></small>
                <ListItemIcon className={ProfileClasses.sideIcon}>
                  <Avatar
                    alt=""
                    src="/assets/images/profilepage/favourite.svg"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Favourite"
                  className={ProfileClasses.menuTitle}
                />
              </ListItemButton>

              <ListItemButton component="a" href="#">
                <small></small>
                <ListItemIcon className={ProfileClasses.sideIcon}>
                  <Avatar
                    alt=""
                    src="/assets/images/profilepage/giftcardIcon.svg"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="My Rewards"
                  className={ProfileClasses.menuTitle}
                />
              </ListItemButton>

              <ListItemButton component="a" href="#">
                <small></small>
                <ListItemIcon className={ProfileClasses.sideIcon}>
                  <Avatar
                    alt=""
                    src="/assets/images/profilepage/cardtick.svg"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Refer & Earn"
                  className={ProfileClasses.menuTitle}
                />
              </ListItemButton>

              <ListItemButton component="a" href="#">
                <small></small>
                <ListItemIcon className={ProfileClasses.sideIcon}>
                  <Avatar
                    alt=""
                    src="/assets/images/profilepage/logout.svg"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Logout"
                  className={ProfileClasses.menuTitle}
                />
              </ListItemButton>
            </List>
          </Box>

          <Box
            className={ProfileClasses.completeProfile}
            sx={{ display: "flex", width: "100%", margin: "auto" }}
          >
            <Box>
              <ProgressBar className={ProfileClasses.progressSet}
                radius={100}
                progress={60}
                strokeWidth={18}
                strokeColor="#faba3f"
                strokeLinecap="round"
                trackStrokeWidth={18}
                counterClockwise
              >
                <div className={ProfileClasses.indicator}>
                  <div>60%</div>
                </div>
              </ProgressBar>
            </Box>
            <Typography
              variant="h6"
              className={ProfileClasses.completeText}
            >
              Complete Your Payrup Profile
            </Typography>
          </Box>

          <Box className={ProfileClasses.helpBox}>
            <Box className={ProfileClasses.helpText}>
              <img
                src="/assets/images/profilepage/chaticon.svg"
                className={ProfileClasses.chatImg}
                alt="Help"
              />
              <Typography variant="h4">Need Help?</Typography>
            </Box>

            <Typography variant="h6">24*7 days assistance</Typography>
            <Button>Chat with Us</Button>
          </Box>
      </Box>
    </Item>
  </>
  );
}
