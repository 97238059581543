import React, { FC, ReactElement } from "react";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import FooterClass from "./Footer.module.css";

const footer_data = [
  {
    id: 1,
    heading: "24*7 Assistance",
    subHeading: "Available throughout the day to assist you",
    image: "assistance.svg",
  },
  {
    id: 2,
    heading: "100% Safe and legit",
    subHeading: "Your security and privacy at our portal is our responsibility",
    image: "safe.svg",
  },
  {
    id: 3,
    heading: "Quick & Easy method to payments",
    subHeading:
      " Pay from wherever you are at whatever time you like through which ",
    image: "easy-method.svg",
  },
];

export const Footer: FC = (): ReactElement => {
  return (
    <Box
			paddingTop={{xs:"5%", md:"3%"}}
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: "#F9FCFF;",
      }}
    >
      <Container maxWidth="xl">
        <Grid className={FooterClass.footerTop}
          container
          spacing={4}
          alignItems="center"
          sx={{ marginBottom: "4%" }}
        >
		  <Grid item xs={12} md={12}>
		    <Typography
              color="black"
              variant="h6"
              sx={{ fontSize: "14px", lineHeight: "20px", paddingTop:"0"}}
            >
			Home 
			
			<img src="/assets/images/Icons/left-long-arrow.svg" style={{margin:"0 10px"}} alt="arrow"/>
			
			<Link
              color="#68A4E3"
              variant="h6"
              sx={{ fontSize: "14px", lineHeight: "20px", paddingTop:"0", textDecoration:"none",fontWeight:"400" }}
            >Mobile Recharge
			   </Link>
			   </Typography>
			   
			     
			
		  </Grid>
          <Grid item xs={12} md={4}>
            <img
              className={FooterClass.footerlogo}
              src="/assets/images/Logo/payrup-logo.png"
              alt="Payrup Logo"
            />
            <Typography
              color="black"
              variant="h6"
              sx={{ fontSize: "14px", lineHeight: "20px",marginTop:"5px",marginBottom:"10px" }}
            >
           India's leading digital platform for all your online recharges, utility payments, products and services purchases. Enjoy all travel and hotel bookings for every trip you plan.
            </Typography>
		
			  <Typography
              color="black"
              variant="h6"
              sx={{ fontSize: "14px", lineHeight: "20px" }}
            >

Welcome to Payrup - your complete digital partner on the internet!
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} display={{ xs: 'none', md: 'block' }}>
            <Typography
              color="textSecondary"
              variant="h5"
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                marginBottom: "15px",
                color: "#2D3144",
              }}
            >
              Quick Links
            </Typography>
            <div className={FooterClass.links}>
              <Link>Mobile & Dth Recharge</Link>
              <Link>Bill Payments </Link>
              <Link>Utility Payments</Link>
              <Link>Travel Booking</Link>
              <Link>eGift Cards</Link>
            </div>
			
			   <Grid item >

                <Link sx={{cursor:"pointer"}}><img src="/assets/images/Icons/facebook.svg" alt=""/></Link>
				<Link sx={{cursor:"pointer"}} marginLeft="5px"><img src="/assets/images/Icons/instagram.svg" alt=""/></Link>
                <Link sx={{cursor:"pointer"}} margin="0 5px"><img src="/assets/images/Icons/linkedin.svg" alt=""/></Link>                
                <Link sx={{cursor:"pointer"}}><img src="/assets/images/Icons/twitter.svg" alt=""/></Link>

            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="xl" sx={{ marginBottom: "3%" }}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          {footer_data.map((item, index) => (
            <Grid item xs={12} sm={6} md={4}>
              <Grid container className={FooterClass.box}>
                <Grid item xs={3} sm={3}>
                  <img
                    src={`/assets/images/homepage/${item.image}`}
                    alt={item.heading}
                  />
                </Grid>
                <Grid item xs={9} sm={9} display="inline-table" alignSelf="center">
                  <Typography variant="h5">{item.heading}</Typography>
                  <Typography variant="h6">{item.subHeading}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Container maxWidth="xl" >
        <Grid container spacing={2} alignItems="center" justifyContent="center" className={FooterClass.modulelinks}>
          <Grid item xs={12}>
            <Typography variant="h3">Mobile Recharges & DTH</Typography>
         
            <div className={FooterClass.links}>
              <h6><Link>Mobile Prepaid</Link></h6>
              <h6><Link>Mobile Postpaid</Link></h6>
              <h6><Link>Dth Recharge</Link></h6>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3">Bill Payments</Typography>
          
            <div className={FooterClass.links}>
              <h6><Link>Broadband</Link></h6>
              <h6><Link>Electricity </Link></h6>
              <h6><Link>Landline Bill</Link></h6>
              <h6><Link>Piped Gas</Link></h6>
              <h6><Link>Water</Link></h6>
              <h6><Link>LPG</Link></h6>
              <h6><Link>Cable</Link></h6>
              <h6><Link>Housing Society</Link></h6>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3">Utility Payments</Typography>
       
            <div className={FooterClass.links}>
              <h6><Link>Life Insurance</Link></h6>
             <h6> <Link>Health Insurance </Link></h6>
              <h6><Link>Loan Repayment</Link></h6>
              <h6><Link>Municipal Taxes</Link></h6>
              <h6><Link>Municipal Services</Link></h6>
              <h6><Link>Subscription</Link></h6>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">Travels Booking</Typography>
         
            <div className={FooterClass.links}>
              <h6><Link>Flight Booking</Link></h6>
             <h6> <Link>Hotel Booking </Link></h6>
             <h6> <Link>Bus Booking</Link></h6>
              <h6><Link>Train Booking</Link></h6>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3">eGift Cards</Typography>
       
            <div className={FooterClass.links}>
             <h6> <Link>eGift Cards</Link></h6>
            </div>
          </Grid>
        </Grid>
      </Container>
	  

      <Container maxWidth="xl" >
        <div className={FooterClass.footerbottom}>
          <Grid
		  display={{ xs: 'none', md: 'flex' }}
            container
            direction="row"
            alignItems="right"
            justifyContent={{xs:"center", md:"space-between"}}
          >
            <Grid item>
              <div className={FooterClass.bottomlinks}>
                <Link>About Us</Link>
                <Link>Blog </Link>
                <Link>Support</Link>
                <Link>FAQs</Link>
                <Link>Terms & Conditions</Link>
                <Link>Privacy Policy</Link>
              </div>
            </Grid>
            <Grid item>
              <p>@2022 Payrup | All rights reserved.</p>
            </Grid>
          </Grid>
        </div>
      </Container>
	  
	  	   <Container maxWidth="xl" >
        <div className={FooterClass.footermobilebottom} >
          <Grid display={{ xs: 'block', md: 'none' }}
            container
            direction="row"
            alignItems="right"
            justifyContent={{xs:"center", md:"space-between"}}
			paddingBottom="4rem"
          >
            <Grid item  textAlign="center">

                <Link><img src="/assets/images/Icons/facebook.svg" alt=""/></Link>
				<Link marginLeft="5px"><img src="/assets/images/Icons/instagram.svg" alt=""/></Link>
                <Link margin="0 5px"><img src="/assets/images/Icons/linkedin.svg" alt=""/></Link>                
                <Link><img src="/assets/images/Icons/twitter.svg" alt=""/></Link>

            </Grid>
            <Grid item textAlign="center" color="#656876">
              <p> @2022 Payrup | All rights reserved.</p>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Box>
  );
};

export default Footer;
