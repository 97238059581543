import React, { ReactElement, FC } from "react";

import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";

import SigninClasses from "./Signin.module.css";

export const UpdateInfo: FC = (): ReactElement => {

  const inputStyle = {
      width: "80%",
      "& .MuiOutlinedInput-root": {
          "& fieldset": { 
              borderRadius: "6px",
          },
      "&.Mui-focused fieldset": {
          borderColor: "#586cd8"
      }
    }
  }

/*------------------Modal----------------*/

  const [updInfo, infoMod] = React.useState(false);
  const closeInfoMod = () => infoMod(false);

/*---------------------------------------*/


  return (
    <>
      
      <Box className={SigninClasses.modalForm}>
        <Grid
          columns={{ xs: 12, md: 12 }}
          spacing={{ xs: 2, sm: 2, md: 2 }}
          container
        >
          <Grid item md={5} sm={5} xs={12} container>
            <img
              src="/assets/images/signin/signin1.svg"
              alt="signin Img"
              className={SigninClasses.signinImg}
            />
          </Grid>
          <Grid item md={7} sm={7} xs={12} container>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              className={SigninClasses.title}
              sx={{ color: "#55ce3c!important" }}
            >
              Congratulations...
            </Typography>

            <Typography className={SigninClasses.label2}>
              Let's know you! Setup your profile to start transacting on Payrup
            </Typography>

            <TextField
              className={SigninClasses.inputRes}
              sx={inputStyle}
              label="What do we call you?"
              placeholder="Enter Your Full Name"
              color="primary"
              type="text"
              helperText=""
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />

            <p
              style={{ margin: "15px auto", display: "block", width: "100%" }}
            ></p>

            <TextField
              className={SigninClasses.inputRes}
              sx={inputStyle}
              label="What's Your Email Address ?"
              placeholder="Enter your email address"
              color="primary"
              type="email"
              helperText=""
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
            />

            <Button
              variant="contained"
              fullWidth
              className={SigninClasses.updateBtn}
            >
              Let's Go
            </Button>

            <Typography className={SigninClasses.later}>
              <Link href="#">I'll do it later</Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    
    </>
  );

};

export default UpdateInfo;
