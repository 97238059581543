import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { Box, Container, Grid, Typography } from "@mui/material";
import KnowClasses from "./Know.module.css";

const know_data = [
  {
    id: 1,
    heading: "Phone & DTH Recharge",
    subHeading: "Stay connected always! Payments within seconds",
    image: "recharge.svg",
  },
  {
    id: 3,
    heading: "eGift Cards",
    subHeading: "Shop the gifts that everyone will cherish ",
    image: "giftcard.svg",
  },
  {
    id: 2,
    heading: "Pay Bill",
    subHeading: "Complete any transaction, with simple easy steps",
    image: "paybill.svg",
  },
 
];

export default function Know() {
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Container
          maxWidth="xl"

        >
		
          <Grid container justifyContent="space-evenly" spacing={2} padding={{xs:"40px 0 0 0", md:"5% 0 0 0"}}>
            <Grid item xs={12} className={KnowClasses.headingdiv}>
              <Typography variant="h4" className={KnowClasses.heading}>
                Get to know Payrup!
              </Typography>
              <p className={KnowClasses.sub}>Your digital payments made easy</p>
            </Grid>
			  </Grid>
 <Grid container justifyContent="space-around" spacing={1} padding={{xs:"0 0 40px 0", md:"0 0 5% 5%"}}>
            {know_data.map((item, index) => (
              <>
                <Grid className={KnowClasses.grid} item xs={12} sm={6} md={6} lg={6}>
                  <Card sx={{ display: "flex" }} className={KnowClasses.box}>
                    <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3} sm={3}>
                      <CardMedia
                        component="img"
                        sx={{ width: "100%" }}
                        image={`/assets/images/homepage/${item.image}`}
                        alt={item.heading}
                      />
                      </Grid>
                      <Grid item xs={9} sm={9}>
                      <Box sx={{ display: "flex", flexDirection: "column" }} className={KnowClasses.boxDesc}>
                       
                          <Typography  variant="h5" fontWeight="500">
                            {item.heading}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                          >
                            {item.subHeading}
                          </Typography>

                          <Typography variant="h3" color="text.secondary">
                            {item.id}
                          </Typography>
                       
                      </Box>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
}
