import * as React from "react";
import Button from "@mui/material/Button";
import { Box, Container, Grid, Typography } from "@mui/material";
import CustomerSupportClasses from "./CustomerSupport.module.css";

export default function CustomerSupport() {
  return (
    <>
      <Box sx={{ width: "100%" }}
	  paddingTop={{xs:"3%"}}
	   paddingBottom={{xs:"3%"}}
	   >
        <Container
          maxWidth="xl"
		  
        >
          <Grid
            className={CustomerSupportClasses.box}
            container
            sx={{ backgroundColor: "primary.dark" }}
          >
            <Grid item xs={7} md={5} className={CustomerSupportClasses.headingdiv}>
              <p className={CustomerSupportClasses.sub}>
                We’re here to help you
              </p>
              <Typography
                variant="h4"
                className={CustomerSupportClasses.heading}
              >
                24/7 Customer Support
              </Typography>
              <Button
                className={CustomerSupportClasses.button}
                size="large"
                sx={{ marginTop: "2rem", marginBottom: "2rem" }}
              >
                Contact Us
              </Button>
            </Grid>
            <Grid item xs={5} md={4}   paddingBottom={{xs:"0px" , md:"20px 0"}} >
              <img src="./assets/images/homepage/customer-support.svg" alt="customer support"/>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
