import * as React from "react";
import Slick, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.css";
import {
  Container,
} from "@mui/material";

class Slider extends React.PureComponent {
  handleBeforeChange = () => {
    console.log("%c handleBeforeChange", "color: blue");
  };

  handleAfterChange = () => {
    console.log("%c handleAfterChange", "color: red");
  };

  slickSettings: Settings = {
    lazyLoad: "ondemand",
    accessibility: false,
    draggable: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1120,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    beforeChange: this.handleBeforeChange,
    afterChange: this.handleAfterChange
  };


  renderItems = () => {
    const items = [
      {id:1, name:"Booking assistance", image:"assistant.png"},
      {id:2, name:"List your property", image:"hotel.png"},
      {id:3, name:"Popular flights", image:"airplane.png"},
      {id:4, name:"Travel guidance", image:"directions.png"},
      {id:5, name:"Free cancellation", image:"cancellation.png"},
      {id:6, name:"Booking assistance", image:"assistant.png"},
    ];


    return items.map((item:any) => (
      <div key={item.id} className="box">
	   <div key={item.id} className="boxInner">
        <img src={`/assets/images/slider/${item.image}`} alt="" />
		<h5>{item.name}</h5>
      </div>
	   </div>
    ));
  };

  public render() {
    return (
      <div>
	   <Container maxWidth="xl">
	   <div className="slider">
        <Slick {...this.slickSettings}>{this.renderItems()}</Slick>
		</div>
		</Container>
      </div>
    );
  }
}

export default Slider;
