import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import OffersClasses from "./Offers.module.css";
import { Box, Container, Grid, Typography } from "@mui/material";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

export default function OffersSlider() {
  const offers_data = [
    {
      id: 1,
      heading: "Hotels",
      subHeading: "Up-to ₹3,000 off on your hotel booking T&C Apply*",
      image: "hotels.png",
    },
    {
      id: 2,
      heading: "Mobile Recharges",
      subHeading: "Deals to save big on your recharges",
      image: "mobile-recharges.png",
    },
    {
      id: 3,
      heading: "Utility Bills",
      subHeading: "now on Payrup. Save every time you recharge",
      image: "utility.png",
    },
    {
      id: 4,
      heading: "eGift Cards",
      subHeading: "Gift your loved ones something to  shop for!",
      image: "egifts.png",
    },
  ];

  /*-----------------------------------------------*/

  return (
    <>
      <Box sx={{ width: "100%", backgroundColor: "primary.light" }}>
        <Container
          maxWidth="xl"
          sx={{ textAlign:"center" }}
        >
          <Grid container spacing={3}
		   padding={{xs:"20px 0", md:"3% 0"}}
		   
		   >
            <Grid item xs={12} className={OffersClasses.headingdiv}>
              <Typography variant="h4" className={OffersClasses.heading}>
                Offers - Just the way you like!
              </Typography>
              <p className={OffersClasses.sub}>
                Make the most of the deals running on Payrup to save big on your
                bookings
              </p>
            </Grid>

            <Grid item xs={12}>
              <Swiper
                slidesPerView={2}
                breakpoints={{
                  0: {
                    slidesPerView: 1.2,
                  },

                  768: {
                    slidesPerView: 3,
                  },

                  1024: {
                    slidesPerView: 3,
                  },

                  1336: {
                    slidesPerView: 4,
                  },
                  1920: {
                    slidesPerView: 4,
                  },
                }}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                style={{ margin: "0" }}
              >
                {offers_data.map((item, index) => (
                  <SwiperSlide>
                    <Grid item 
					margin={{xs:"0px 5px 25px 5px", md:"0px 15px 25px 15px"}}
					>
                      <Card
                        className={OffersClasses.card}
                        sx={{ borderRadius: "10px" }}
                      >
                        <CardMedia
                          component="img"
                          image={`/assets/images/offers/${item.image}`}
                          alt={item.heading}
                        />

                        <CardContent>
                          <Typography gutterBottom variant="h5">
                            {item.heading}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {item.subHeading}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
