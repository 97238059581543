import MenuClasses from "./Menu.module.css";
import * as React from "react";
import { Grid,Container, Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Menus from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import TabList from "../tab/Tab"
import BillpaymentTab from "../tab/BillpaymentTab"


const data = [
  { id: 1, heading: "Mobile", subHeading: "Recharge & DTH ", image: "recharge.svg",  },
  { id: 2, heading: "Bill", subHeading: "Payments", image: "bill-payment.svg" },
  { id: 3, heading: "Travel", subHeading: " Bookings", image: "travel.svg" },
  { id: 4, heading: "eGift", subHeading: " Cards", image: "egift.svg" },
];


function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`module-tabpanel-${index}`}
      aria-labelledby={`module-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `module-tab-${index}`,
    "aria-controls": `module-tabpanel-${index}`,
  };
}

export default function MainModule() {
	 

  const [value, setvalues] = React.useState(0);

  const handleChange = (event: any, newvalues: any) => {
    setvalues(newvalues);
  };
 	  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
<>
      <Container maxWidth="xl">
	  	<div  className={MenuClasses.tabs}>
	     <Tabs
              value={value}
              onChange={handleChange}
              aria-label="module tabs"
              textColor="inherit"
			  variant="scrollable"
				scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "transient", height:"0px", bottom:"0px",borderRadius:"5px" } }}
            >
 {data.map((item, index) => (
                   <Tab className={MenuClasses.tabHeading}
			      
                label={<div><h2>{item.heading}</h2><span>{item.subHeading}</span></div> }
                icon={
                  <img
                       src={`/assets/images/Icons/${item.image}`}
                          alt={item.heading}
                  />
                }
                iconPosition="start"
                {...a11yProps(item.id)}
              />
                ))}
		
			
			 <div 
                   
                                 
                    className={MenuClasses.tabHeading}
					  id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
       onClick={handleClick}
                  >
                

                <Grid
                    container                   
                    alignItems="center"
                    justifyContent="center"
					 className={MenuClasses.more}
                  >
                    <Grid item >
                    <img src="/assets/images/Icons/more.svg" alt="more"/>
                    </Grid>
                    <Grid item display="inline-table"
                    alignSelf="center" >
                    <Typography variant="h2">
                     More
                    </Typography>
                    </Grid>
                  </Grid>                   
                     
                   
                  </div>
		   
			  
		
		
            </Tabs>
			
		
     	   </div>


		  
		  	   <Menus
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
		className={MenuClasses.moreMenu}
		sx={{height:350}}
      >
      
		  <MenuItem onClick={handleClose}>
          <ListItemIcon>
           <img src="/assets/images/Icons/cable.svg" alt=""/>
          </ListItemIcon>
          <ListItemText>Cable</ListItemText>
        </MenuItem>
		  <MenuItem onClick={handleClose}>
          <ListItemIcon>
           <img src="/assets/images/Icons/credit-card.svg" alt=""/>
          </ListItemIcon>
          <ListItemText>Credit Card</ListItemText>
        </MenuItem>
		  <MenuItem onClick={handleClose}>
          <ListItemIcon>
           <img src="/assets/images/Icons/health-insurance.svg" alt=""/>
          </ListItemIcon>
          <ListItemText>Health Insurance</ListItemText>
        </MenuItem>
		  <MenuItem onClick={handleClose}>
          <ListItemIcon>
           <img src="/assets/images/Icons/housing-society.svg" alt=""/>
          </ListItemIcon>
          <ListItemText>Housing Society</ListItemText>
        </MenuItem>
		  <MenuItem onClick={handleClose}>
          <ListItemIcon>
           <img src="/assets/images/Icons/insurance.svg" alt=""/>
          </ListItemIcon>
          <ListItemText>Insurance</ListItemText>
        </MenuItem>
		  <MenuItem onClick={handleClose}>
          <ListItemIcon>
           <img src="/assets/images/Icons/loan-repayment.svg" alt=""/>
          </ListItemIcon>
          <ListItemText>Loan repayment</ListItemText>
        </MenuItem>
		  <MenuItem onClick={handleClose}>
          <ListItemIcon>
           <img src="/assets/images/Icons/credit-card.svg" alt=""/>
          </ListItemIcon>
          <ListItemText>Credit card</ListItemText>
        </MenuItem>
		  <MenuItem onClick={handleClose}>
          <ListItemIcon>
           <img src="/assets/images/Icons/hospital.svg" alt=""/>
          </ListItemIcon>
          <ListItemText>Hospital</ListItemText>
        </MenuItem>
      </Menus>
	  
	
	 <Box display={{ xs: "none", sm: "block" }}  sx={{width: "100%" }}> 
  <TabPanel value={value} index={0}>
<TabList/>

  </TabPanel>
    <TabPanel value={value} index={1}>
<BillpaymentTab/>
   </TabPanel>
  <TabPanel value={value} index={2}>
<TabList/>
   </TabPanel>
     <TabPanel value={value} index={3}>
<TabList/>
   </TabPanel>
   
  </Box> 
		  </Container>
		  </>

  );
}
