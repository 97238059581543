import React, { ReactElement, FC } from "react";

import {
  Box,
  Button,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";

import Modal from '@mui/material/Modal';
import UpdateInfo from "../Signin/UpdateInfo";

import SigninClasses from "./Signin.module.css";

export const OTP: FC = (): ReactElement => {


  const inputStyle = {
      width: "80%",
      "& .MuiOutlinedInput-root": {
          "& fieldset": { 
              borderRadius: "6px",
          },
      "&.Mui-focused fieldset": {
          borderColor: "#586cd8",
      }
    }
  }

  /*------------------ Modal JS -------------------*/
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
    
  /*------------------Modal----------------*/

  const [updInfo, infoMod] = React.useState(false);
  const closeInfoMod = () => infoMod(false);

  /*---------------------------------------*/


  return (
    <>

      <Box className={SigninClasses.modalForm}>
        <Grid
          columns={{ xs: 12, md: 12 }}
          spacing={{ xs: 2, sm: 2, md: 2 }}
          container
        >
          <Grid item md={5} sm={5} xs={12} container>
            <img
              src="/assets/images/signin/signin2.svg"
              alt="signin Img"
              className={SigninClasses.signinImg}
            />
          </Grid>
          <Grid item md={7} sm={7} xs={12} container>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              className={SigninClasses.title}
            >
              Verify OTP
            </Typography>

            <Box sx={{ display: "flex", marginBottom: "25px" }}>
              <Typography
                variant="h5"
                className={SigninClasses.label}
                sx={{ fontSize: "14px" }}
              >
                Enter 4 digit OTP sent to <b>*******567 </b>
              </Typography>
              <Link href="#" sx={{ margin: "0 6px" }}>
                <img
                  src="/assets/images/signin/edit.svg"
                  alt="Edit"
                  className={SigninClasses.editIcon}
                />
              </Link>
            </Box>

            <TextField
              className={SigninClasses.inputRes}
              sx={inputStyle}
              label=""
              placeholder=""
              color="primary"
              type="text"
              helperText=""
              fullWidth
            />

            <Box
              className={SigninClasses.validate}
              sx={{ width: "100%", marginTop: "20px" }}
            >
              <Typography sx={{ color: "green", fontSize: "14px" }}>
                OTP Verified
              </Typography>
            </Box>

            <Box className={SigninClasses.otpSet}>
              <Typography sx={{ fontSize: "13px", display: "none" }}>
                <img
                  src="/assets/images/signin/sloader.svg"
                  alt="Loader"
                  className={SigninClasses.sloader}
                />
                Verifying OTP ...
              </Typography>

              <Typography sx={{ fontSize: "12px", display: "inherit" }}>
                Didn't get the OTP? Resend OTP in &nbsp;<b>00:30</b>
              </Typography>

              <Typography
                sx={{ fontSize: "12px", color: "#586cd8", fontWeight: "600" }}
              >
                <Link href="#">Resend OTP</Link>
              </Typography>
            </Box>

            <Button
              variant="contained"
              fullWidth
              className={SigninClasses.updateBtn}
              sx={{ marginBottom: "60px!important" }}
              onClick={() => { infoMod(true); }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Modal
        open={updInfo}
        onClose={closeInfoMod}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={SigninClasses.modal}>
          <Button onClick={closeInfoMod} className={SigninClasses.closeBtn}>
            <img src="/assets/images/signin/close.png" alt="close" />
          </Button>

          <UpdateInfo />

        </Box>
      </Modal>
    </>
  );

};

export default OTP;
