import React, { ReactElement, FC } from "react";
import PostpaidForm from "../components/Modules/PostpaidForm";

const Prepaid: FC<any> = (): ReactElement => {
  return (
    <>

   <PostpaidForm/>

   </>
  );
};

export default Prepaid;