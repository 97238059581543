import * as React from "react";
import { styled } from "@mui/material/styles";

import {
  Box,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";

import ProfileClasses from "./Profile.module.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';


export default function MyrupeeBox() {
  
  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    borderRadius: "14px",
    boxShadow: "none"
  }));

  /*Giftcard Data*/

  function giftCardData(image: string, link: string) {
    return { image, link};
  }

  const row = [
    giftCardData("amazonCard.svg", "#"),
    giftCardData("bigBasket.svg", "#"),
    giftCardData("shoppeStore.svg", "#"),
    giftCardData("amazonCard.svg", "#"),
    giftCardData("bigBasket.svg", "#"),
    giftCardData("shoppeStore.svg", "#"),
  ];
  
  /*-----------------------------------------------*/
  
  return (
    <>
      <Item sx={{marginBottom: ['20px','30px','40px']}}>
        <Box className={ProfileClasses.profileDetails}>
          <Typography variant="h3">MyRupee</Typography>

          <Box className={ProfileClasses.myRupee}>
            <Grid columns={{ sm: 12, md: 12 , xs: 12}} spacing={{ xs: 4, sm:4, md: 4 }} container>
              <Grid item md={4} sm={6} xs={12} container className={ProfileClasses.rupeeBag}>
                <div style={{display:"flex"}}>
                  <img
                    src="/assets/images/profilepage/rupeeBag.svg"
                    alt="Rupee Bag"
                  />
                  <Typography className={ProfileClasses.rupeeText}> 
                    <span style={{color:"#586cd8"}}>&#8377;</span> 300
                  </Typography>
                </div>
              </Grid>
                
              <Grid item md={4} sm={6} xs={12} 
                sx={{
                  margin: "auto",
                  textAlign: "center"}}
                > 
                <Typography variant="h6" className={ProfileClasses.myRupeeText}>
                  Congrats, here are your savings!
                </Typography>
              </Grid>

              <Grid item md={4} sm={12} xs={12}>
                <Box sx={{margin: "auto",textAlign: "center"}}> 
                  <img
                    src="/assets/images/profilepage/refferalBack.svg"
                    alt="Gift Box"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          
          <Box className={ProfileClasses.giftCardSet}>
            <Swiper
              slidesPerView={3}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                
                768: {
                  slidesPerView: 2,
                },

                1336: {
                  slidesPerView: 3,
                },
                1920: {
                  slidesPerView: 4,
                }
              }}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
              style={{margin: "0"}}
              
            >
            {row.map((row) => (
              <SwiperSlide className={ProfileClasses.swiperSet}>
                <Link href={row.link} underline="none">
                  <img
                      src={"/assets/images/profilepage/" + row.image}
                      alt="Gift Box"
                    />
                  </Link>
              </SwiperSlide>
              ))}
              
            </Swiper>
           
          </Box>

        </Box>
      </Item>
    </>
  );
}
